import React from "react";

import { base, navigation, content } from "./termsofservice.module.scss";

import Layout from "../layouts/Layout";

const TermsOfServicePage = props => {
  return (
    <Layout isFixed noFooter pageTitle="Terms | Brandcrush">
      <article className={base}>
        <h1 className="mobile-only">Terms of Service</h1>
        <nav className={navigation}>
          <ul>
            <li>
              <a href="#introduction">Introduction</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#scope">Scope of the Brandcrush Platform</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#eligibility">Eligibility to use Brandcrush Platform</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#registration">Account registration</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#activation-partners">Terms specific to Activation Partners</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#brands">Terms specific to Brands</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#service-fees">Service &amp; Subscription Fees</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#content">Content</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#insurance">Insurance</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#ratings">Ratings and Reviews</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#taxes">Taxes</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#prohibited">Prohibited Activities</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#term">Term, termination and suspension</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#interaction">Interaction with Other Members</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#indemnification">Indemnification</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#disclaimers">Disclaimers</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#liability">Liability</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#dispute">Dispute</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#general">General Provisions</a>
              <br />
              <br />
            </li>
            <li>
              <a href="#brandcrush">BRANDCRUSH - HOUSE RULES</a>
              <br />
              <br />
            </li>
          </ul>
        </nav>
        <div className={content}>
          <h1 className="desktop-only">Terms of Service</h1>
          <section id="introduction">
            <h2>Introduction</h2>
            <p>
              <b>
                Please read these Terms of Service carefully because they contain important
                information about your legal rights, remedies and obligations.
              </b>
            </p>
            <p>
              Please see FAQs for a description of terms used throughout these Terms of Service
              which are not defined in this document.
            </p>
            <p>Last Updated: Effective from April 24th, 2022</p>
            <p>
              These Terms of Service (<b>“Terms”</b>) constitute a legally binding agreement (
              <b>“Agreement”</b>) between you and Brandcrush Pty Ltd ACN 617 383 052, it's
              affiliates or agents (collectively,
              <b>“Brandcrush”</b>) governing your access and use of:
            </p>
            <p>
              <ul>
                <li>
                  the Brandcrush Platform and any websites used by Brandcrush to offer or deliver
                  its services including the website brandcrush.com (collectively, <b>Website</b>);
                </li>
                <li>
                  Brandcrush’s web, mobile, tablet and other smart device applications and
                  application program interfaces (collectively, <b>Application</b>); and
                </li>
                <li>
                  all services provided by Brandcrush in connection with the Website and Application
                  (<b>Services</b>).
                </li>
              </ul>
            </p>
            <p>
              The Website, Application and Services together are collectively referred to as the{" "}
              <b>“Brandcrush Platform.”</b> By clicking “sign up” or “continue with,” completing the
              registration process, and/or browsing the Website or downloading the Application, you
              represent that: (1) you have read and agree to be bound by these Terms as well as our
              House Rules and Privacy Policy, which are all incorporated by reference into this
              Agreement; (2) you are of legal age to form a binding contract with Brandcrush; and
              (3) you have the authority to enter into the Terms personally or on behalf of the
              company you have named as the user, and to bind that company to the Terms.{" "}
              <b>
                If you do not agree to be bound by the Terms of Service, you may not access or use
                the Platform or its Services.
              </b>
            </p>
            <p>
              <b>
                CLAUSE 17 OF THESE TERMS OF SERVICE IS AN ARBITRATION CLAUSE THAT REQUIRES MOST
                DISPUTES BETWEEN US TO BE RESOLVED ON AN INDIVIDUAL, NON-CLASS ACTION BASIS THROUGH
                BINDING AND FINAL ARBITRATION INSTEAD OF IN COURT. SEE CLAUSE 17 FOR MORE
                INFORMATION REGARDING THIS ARBITRATION CLAUSE, AND HOW TO OPT OUT.
              </b>
            </p>
            <p>
              The access to or use of certain areas and features of the Brandcrush Platform may be
              subject to separate policies, standards or guidelines, or may require that you accept
              additional terms and conditions <b>(“Supplemental Terms”)</b>. If there is a conflict
              between these Terms and terms and any Supplemental Terms, the Supplemental Terms will
              take precedence with respect to your access to or use of that area or feature, unless
              specified otherwise.
            </p>
            <p>
              Certain Space Listings (for example, Space Listings offering Activations at organised
              events) may be subject to special terms and conditions <b>(“Special Terms”)</b> (for
              example, stricter cancellation policies) that override these Terms to the extent of
              any inconsistency. You will be notified on a Space Listing, through an email or a
              workflow if Special Terms exist and be prompted to read the Special Terms before
              signing and submitting them to the Activation Partner for approval. Certain types of
              Activation Partners (for example, Event Partners) may also be subject to special terms
              and conditions that override these Terms to the extent of any inconsistency. These
              additional terms and conditions may be notified to you by any means (including by
              email or by providing a link referring you to view an online document).
            </p>
            <p>
              <b>
                Activation Partners are solely responsible for identifying and complying with all
                laws and rules that apply to their Space Listings and Activations. In some
                jurisdictions, Activation Partners must obtain a permit or license before providing
                certain Activations (for example, permits and licenses associated with serving
                liquor, offering liquor for sale, food preparation and handling, fundraising
                activities, providing diagnostic or health advice and broadcasting). If you require
                guidance or assistance with complying with the relevant laws and rules, you should
                contact the applicable regulatory authorities and obtain legal advice as required.
              </b>
            </p>
            <p>
              Subject to clause 17.9 of these Terms of Use, Brandcrush reserves the right to modify
              the Terms or its policies relating to the Services at any time, effective upon posting
              of an updated version of the Terms or any applicable Supplemental Terms for the
              applicable Services. Any changes which affect your rights and obligations in respect
              of subscriptions or other purchases will be effective fifteen (15) days after posting
              notice of such changes for existing users with Brandcrush Accounts. You should
              regularly review these Terms, as your continued use of the Brandcrush Platform after
              any such changes constitutes your agreement to such changes. If we make any material
              changes to this Agreement, and you have registered with us to create a Brandcrush
              Account (as defined in clause 3.2 below), we will also inform you of such changes by
              writing to you using the last e-mail address you provided to us.
            </p>
          </section>
          <section id="scope">
            <h2>1. Scope of the Brandcrush Platform</h2>
            <h3>1.1 Overview</h3>
            <p>
              The Brandcrush Platform is an online marketplace and software that enables registered
              users (<b>“Members”</b>) (Members who offer services are{" "}
              <b>“Activation Partners” or “Partners”</b> (previously Activation Hosts) and the
              services they offer are <b>“Activations”</b>) to advertise Activations on the
              Brandcrush Platform (<b>“Space Listings”</b>) and to communicate and transact directly
              with Members who wish to book Activations (Members using Activations are{" "}
              <b>“Brands”</b>). Activations may be by way of product sampling, pop up, partnerships,
              sponsorships, in-location digital and print and other media activation units including
              in-store, in-home and on-line formats. Activations include the use by Brands of
              physical and digital spaces with existing foot traffic or customer reach (
              <b>“Activation or Media Spaces”</b>) and may include the provision of staff to deliver
              a brand or product message on behalf of the brand.
            </p>
            <h3>1.2 Legal relationship between Activation Partners and Brands</h3>
            <p>
              As the provider of the Brandcrush Platform, Brandcrush does not own, create, sell,
              resell, provide, control, manage, offer, deliver, or supply any Listings or
              Activations. Activation Partners are solely responsible for their Space Listings and
              Activations. When Members make or accept a booking, they are entering into a contract
              directly with each other. Brandcrush is not and does not become a party to, or other
              participant in, any contractual relationship between Members. Except in respect of
              facilitating the formation of a contractual relationship between Activation Partners
              and Brands in respect of Activation Partner Supplementary Terms in accordance with
              clauses 4.11 and 5.6, Brandcrush is not acting as an agent in any capacity for any
              Member.
            </p>
            <p>
              This Agreement is non-exclusive as between Activation Partners and Brands and does not
              prevent or restrict either of those parties from entering into similar or different
              agreements with third parties.
            </p>
            <h3>1.3 Responsibility for Member Content</h3>
            <p>
              While Brandcrush may from time to time help facilitate the resolution of disputes
              between Members, Brandcrush has no control over and does not guarantee:
            </p>
            <p>
              <ul>
                <li>
                  the existence, quality, safety, suitability, or legality of any Space Listings or
                  Activations;
                </li>
                <li>the truth, completeness or accuracy of any Space Listing descriptions;</li>
                <li>
                  Ratings, Reviews, or other Member Content (as defined in clause 7 below); or
                </li>
                <li>the performance or conduct of any Member or third party.</li>
              </ul>
            </p>
            <p>
              Brandcrush does not endorse any Member, Space Listing or Activations. Any references
              to a Member being “verified” (or similar language) only indicates that the Member has
              completed a relevant verification or identification process and nothing else. Any such
              description is not an endorsement, certification or guarantee by Brandcrush about any
              Member, including of the Member's identity or background or whether the Member is
              trustworthy, safe or suitable. You should always exercise due diligence and care when
              deciding whether to book or accept an Activation request or when communicating and
              interacting with other Members, whether online or in person.
            </p>
            <p>
              Authorized Images (as defined in clause 7.6(i) below) are intended only to indicate a
              photographic representation of a Space Listing at the time the photograph was taken,
              and are therefore not an endorsement by Brandcrush of any Activation Partner or Space
              Listing.
            </p>
            <h3>1.4 Legal relationship between Brandcrush and Activation Partners</h3>
            <p>
              If you choose to use the Brandcrush Platform as an Activation Partner, your
              relationship with Brandcrush is limited to being an independent, third-party
              contractor, and not an employee, agent, joint venturer or partner of Brandcrush for
              any reason, and you act exclusively on your own behalf and for your own benefit, and
              not on behalf, or for the benefit, of Brandcrush. Brandcrush does not, and shall not
              be deemed to, direct or control you generally or in your performance under these Terms
              specifically, including in connection with your provision of Activations. You
              acknowledge and agree that you have complete discretion whether to list Activations or
              otherwise engage in other business or employment activities. 
            </p>
            <h3>1.5 Advertisements</h3>
            <p>
              To promote the Brandcrush Platform and to increase the exposure of Space Listings to
              potential Members, Space Listings and other Member Content may be displayed on other
              websites, in applications, within emails, and in online and offline advertisements.
            </p>
            <h3>1.6 Third Party Services</h3>
            <p>
              The Brandcrush Platform may contain links to third party websites or resources (
              <b>“Third Party Services”</b>). Such Third Party Services may be subject to different
              terms and conditions and privacy practices. Brandcrush is not responsible or liable
              for the availability or accuracy of such Third Party Services, or the content,
              products, or services available from such Third Party Services. Links to such Third
              Party Services are not an endorsement by Brandcrush of such Third Party Services.
            </p>
            <p>
              <ul>
                <li>
                  <b>Application License:</b> Subject to your compliance with the Terms, Brandcrush
                  grants you a limited non-exclusive, non-transferable, non-sublicensable, revocable
                  license to download, install and use a copy of the Application on a single mobile
                  device or computer that you own or control and to run such copy of the Application
                  solely for your own personal or internal business purposes. Furthermore, with
                  respect to any Application accessed through or downloaded from the Apple App Store
                  (an <b>“App Store Sourced Application”</b>), you will only use the App Store
                  Sourced Application (a) on an Apple-branded product that runs the iOS (Apple’s
                  proprietary operating system) and (b) as permitted by the “Usage Rules” set forth
                  in the Apple App Store Terms of Service. Notwithstanding the first sentence in
                  this clause, with respect to any Application access through or downloaded from the
                  Google Play store (a “Google Play Sourced Application”), you may have additional
                  license rights with respect to use of the Application on a shared basis within
                  your designated family group.
                </li>
                <li>
                  App Stores: You acknowledge and agree that the availability of the Application and
                  the Services is dependent on the third party from whom you received the
                  Application license, e.g., the Apple App Store or Google Play (each, an{" "}
                  <b>“App Store”</b>). You acknowledge that the Terms is between you and Brandcrush
                  and not with the App Store. Brandcrush, not the App Store, is solely responsible
                  for the Brandcrush Platform, including the Application, the content thereof,
                  maintenance, support services, and warranty therefor, and addressing any claims
                  relating thereto (e.g., product liability, legal compliance or intellectual
                  property infringement). In order to use the Application, you must have access to a
                  wireless network, and you agree to pay all fees associated with such access. You
                  also agree to pay all fees (if any) charged by the App Store in connection with
                  the Brandcrush Platform, including the Application. You agree to comply with, and
                  your license to use the Application is conditioned upon your compliance with all
                  terms of Terms imposed by the applicable App Store when using the Brandcrush
                  Platform or any Service, including the Application. You acknowledge that the App
                  Store (and its subsidiaries) are third-party beneficiaries of the Terms and will
                  have the right to enforce it.
                </li>
                <li>
                  <b>Accessing and Downloading the Application from iTunes.</b> The following
                  applies to any App Store Sourced Application accessed through or downloaded from
                  the Apple App Store:
                  <ul>
                    <li>
                      You acknowledge and agree that (i) the Terms is concluded between you and
                      Brandcrush only, and not Apple, and (ii) Brandcrush, not Apple, is solely
                      responsible for the App Store Sourced Application and content thereof. Your
                      use of the App Store Sourced Application must comply with the App Store Terms
                      of Service.{" "}
                    </li>
                    <li>
                      You acknowledge that Apple has no obligation whatsoever to furnish any
                      maintenance and support services with respect to the App Store Sourced
                      Application.{" "}
                    </li>
                    <li>
                      In the event of any failure of the App Store Sourced Application to conform to
                      any applicable warranty, you may notify Apple, and Apple will refund the
                      purchase price for the App Store Sourced Application to you and to the maximum
                      extent permitted by applicable law, Apple will have no other warranty
                      obligation whatsoever with respect to the App Store Sourced Application. As
                      between Brandcrush and Apple, any other claims, losses, liabilities, damages,
                      costs or expenses attributable to any failure to conform to any warranty will
                      be the sole responsibility of Brandcrush.{" "}
                    </li>
                    <li>
                      You and Brandcrush acknowledge that, as between Brandcrush and Apple, Apple is
                      not responsible for addressing any claims you have or any claims of any third
                      party relating to the App Store Sourced Application or your possession and use
                      of the App Store Sourced Application, including, but not limited to: (i)
                      product liability claims; (ii) any claim that the App Store Sourced
                      Application fails to conform to any applicable legal or regulatory
                      requirement; and (iii) claims arising under consumer protection or similar
                      legislation.
                    </li>
                    <li>
                      You and Brandcrush acknowledge that, in the event of any third-party claim
                      that the App Store Sourced Application or your possession and use of that App
                      Store Sourced Application infringes that third party’s intellectual property
                      rights, as between Brandcrush and Apple, Brandcrush, not Apple, will be solely
                      responsible for the investigation, defense, settlement and discharge of any
                      such intellectual property infringement claim to the extent required by the
                      Terms.{" "}
                    </li>
                    <li>
                      You and Brandcrush acknowledge and agree that Apple, and Apple’s subsidiaries,
                      are third-party beneficiaries of the Terms as related to your license of the
                      App Store Sourced Application, and that, upon your acceptance of the terms and
                      conditions of the Terms, Apple will have the right (and will be deemed to have
                      accepted the right) to enforce the Terms as related to your license of the App
                      Store Sourced Application against you as a third-party beneficiary thereof.{" "}
                    </li>
                    <li>
                      Without limiting any other terms of the Terms, you must comply with all
                      applicable third-party terms of Terms when using the App Store Sourced
                      Application.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Accessing and Downloading the Application from the Google Play Store.</b> The
                  following applies to any App Store Sourced Application accessed through or
                  downloaded from the Google Play Store:
                  <ul>
                    <li>
                      You acknowledge and agree that (i) the Terms is concluded between you and
                      Brandcrush only, and not Google, Inc. (<b>“Google”</b>), and (ii) Brandcrush,
                      not Google, is solely responsible for the Google Play Sourced Application and
                      content thereof. Your use of the Google Play Sourced Application must comply
                      with the Google Play Terms of Service.
                    </li>
                    <li>
                      Google is only a provider of Google Play where you obtained the Google Play
                      Sourced Application.{" "}
                    </li>
                    <li>
                      You acknowledge and agree that Google is a third-party beneficiary to the
                      Terms as it relates to Brandcrush’s Google Play Sourced Application.
                    </li>
                    <li>
                      Brandcrush, and not Google, is solely responsible for its Google Play Sourced
                      Application;
                    </li>
                    <li>
                      Google has no obligation or liability to you with respect to Brandcrush’s
                      Google Play Sourced Application or these Terms; and
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
            <h3>1.7 Access and availability of Brandcrush Platform</h3>
            <p>
              Due to the nature of the Internet, Brandcrush cannot guarantee the continuous and
              uninterrupted availability, availability in any particular location, and accessibility
              of the Brandcrush Platform. Brandcrush may restrict the availability of the Brandcrush
              Platform or certain areas or features thereof, if this is necessary in view of
              capacity limits, the security or integrity of our servers, or to carry out maintenance
              measures that ensure the proper or improved functioning of the Brandcrush Platform.
              Brandcrush may improve, enhance and modify the Brandcrush Platform and introduce new
              Brandcrush Services from time to time.
            </p>
          </section>
          <section id="eligibility">
            <h2>2 Eligibility to use Brandcrush Platform</h2>
            <h3>2.1 Members</h3>
            <p>
              In order to access and use the Brandcrush Platform or register as a Member, you must:
            </p>
            <p>
              <ul>
                <li>
                  be an entity which is registered by the applicable government authority to carry
                  on business in the countries in which you seek to either book or carry out
                  Activations;
                </li>
                <li>
                  be registered for GST or equivalent sales tax in the countries where you seek to
                  book or carry out Activations; and
                </li>
                <li>
                  if registering as an Activation Partner, be able to provide an estimate of foot
                  traffic, customer or audience reach (“<b>Market  Reach  Estimate</b>”) (which is
                  verifiable having regard to past and current data) and sufficiently accurate to
                  enable Brands to assess whether the fees proposed for an Activation represent fair
                  value and decide whether to book a particular Activation.
                </li>
              </ul>
            </p>
            <h3>2.2 Representations and warranties</h3>
            <p>By accessing or using the Brandcrush Platform you represent and warrant that:</p>
            <p>
              <ul>
                <li>
                  you are an entity which is legally entitled to carry on business in the country
                  where you are seeking to book or carry out Activations and have the legal capacity
                  and authority to enter into a contract;
                </li>
                <li>
                  if you are an individual registering on behalf of an entity, you have the
                  authority to legally bind that entity and grant us all permissions and licenses
                  provided in these Terms;
                </li>
                <li>
                  you are registered for GST or equivalent sales tax in the countries where you seek
                  to book or carry out Activations; and
                </li>
                <li>
                  if registering as an Activation Partner, your Market Reach Estimate in respect of
                  each Listing is sufficiently accurate and verifiable.
                </li>
              </ul>
            </p>
            <h3>2.3 Further conditions</h3>
            <p>
              Brandcrush may make access to and use of the Brandcrush Platform, or certain areas or
              features of the Brandcrush Platform, subject to certain conditions or requirements,
              such as completing a verification process, meeting specific quality or eligibility
              criteria, meeting Ratings or Reviews thresholds, or a Member’s booking and
              cancellation history.
            </p>
            <h3>2.4 Member verification</h3>
            <p>
              Member verification without face-to-face meetings is difficult and we do not assume
              any responsibility for the confirmation of any Member’s identity. Notwithstanding the
              above, for transparency and fraud prevention purposes, and as permitted by applicable
              laws, we may, but have no obligation to:
            </p>
            <p>
              <ul>
                <li>
                  ask Members to provide an extract from a government authority (for example, ASIC
                  certificate of incorporation) or other information or undertake additional checks
                  designed to help verify the identities or backgrounds of Members; and
                </li>
                <li>
                  screen Members against third party databases (for example, credit reporting
                  bodies) or other sources and request reports from service providers.
                </li>
              </ul>
            </p>
          </section>
          <section id="registration">
            <h2>3. Account registration</h2>
            <h3>3.1 Activation Partners and Brands</h3>
            <p>
              You must register an account (<b>“Brandcrush Account”</b>) to access and use certain
              features of the Brandcrush Platform, such as publish a Listing, book an Activation or
              receive a booking for an Activation. You may not register more than one (1) Brandcrush
              Account unless otherwise authorized in writing by Brandcrush. You may not assign or
              otherwise transfer your Brandcrush Account to any third party.
            </p>
            <h3>3.2 Registration process</h3>
            <p>
              You can register a Brandcrush Account using an email address, creating a password and
              providing certain business and contact information. You represent that you are (i) at
              least eighteen (18) years old; (ii) of legal age to form a binding contract; and (iii)
              not a person barred from using the Brandcrush Platform under the laws of your place of
              residence or any other applicable jurisdiction. You are responsible for all activities
              that occur under your Brandcrush Account.
            </p>
            <h3>3.3 Obligation to keep up-to-date</h3>
            <p>
              You must provide accurate, current and complete information during the registration
              process and keep your Brandcrush Account and public Brandcrush Account profile page
              information up-to-date at all times.
            </p>
            <p>
              If you provide any information that is untrue, inaccurate, not current or incomplete,
              or Brandcrush has reasonable grounds to suspect that any information you provide is
              untrue, inaccurate, not current or incomplete, Brandcrush has the right to suspend or
              terminate your Brandcrush Account and refuse any and all current or future use of the
              Brandcrush Platform (or any portion thereof).
            </p>
            <h3>3.4 Credentials</h3>
            <p>
              You are responsible for maintaining the confidentiality and security of your
              Brandcrush Account credentials and must not disclose your credentials to any third
              party. You must immediately notify Brandcrush if you know or have any reason to
              suspect that your credentials have been lost, stolen, misappropriated, or otherwise
              compromised or in case of any actual or suspected unauthorized use of your Brandcrush
              Account. You are liable for any and all activities conducted through your Brandcrush
              Account, unless such activities are not authorized by you and you are not otherwise
              negligent (such as failing to report the unauthorized use or loss of your
              credentials).
            </p>
            <h3>3.5 Authorised third parties</h3>
            <p>
              <ul>
                <li>
                  Brandcrush may enable features that allow you to authorize other Members or
                  certain third parties (including media agencies, resellers and team members) (
                  <b>“Authorized Third Party”</b>) to take certain actions that affect your
                  Brandcrush Account. For example, we may allow eligible Members or certain third
                  parties to publish Listings or book Activations on behalf of other Members. These
                  features do not require that you share your credentials with any other person. No
                  third party is authorized by Brandcrush to ask for your credentials, and you must
                  not request the credentials of another Member.
                </li>
                <li>
                  Subject to clause 6.4, you may request that Brandcrush issue an invoice for
                  Activation Partner Fees and/or Brand Fees to an Authorized Third Party for payment
                  on your behalf.
                </li>
                <li>
                  If you appoint an Authorized Third Party to act as your agent in connection with
                  the Brandcrush Platform (including to administer an Activation Partner’s
                  Listing(s) or book Activations on behalf of Brands), you and the Authorized Third
                  Party represent and warrant that:
                  <ul>
                    <li>
                      the Authorized Third Party has been duly appointed and authorized to act on
                      your behalf in connection with the Brandcrush Platform (including to receive
                      and remit payments);
                    </li>
                    <li>
                      you remain fully responsible for the performance of your obligations under
                      these Terms; and
                    </li>
                    <li>
                      any agreement formed between you and an Authorized Third Party does not
                      conflict with these Terms.
                    </li>
                  </ul>
                </li>
                <li>
                  Media agencies using the Brandcrush Platform acknowledge and agree that the
                  Activation Partner Fees and/or Brand Fees is a cost of good and is
                  non-commissionable. No agency rebated will be given.
                </li>
              </ul>
            </p>
          </section>
          <section id="activation-partners">
            <h2>4. Terms specific to Activation Partners</h2>
            <h3>4.1 Terms that apply to all Space Listings</h3>
            <p>
              <ul>
                <li>
                  When creating a Listing through the Brandcrush Platform you must:
                  <ul>
                    <li>
                      provide complete and accurate information about your Activation capacity
                      (including details of your Activation Space and staff availability);
                    </li>
                    <li>
                      disclose any deficiencies, restrictions and requirements that apply; and
                    </li>
                    <li>provide any other pertinent information requested by Brandcrush.</li>
                  </ul>
                </li>
                <li>
                  You are responsible for keeping your Listing information (including availability)
                  up-to-date at all times. Activation Partners, not Brandcrush, is solely
                  responsible for all such Listing information and the content therein that
                  Activation Partner creates a Listing for the Brandcrush Platform.
                </li>
                <li>
                  You are solely responsible for setting a price (including any Taxes, if
                  applicable) for your Activation Fee. Once a Brand requests a booking for an
                  Activation, you must not request that the Brand pays a higher price than the
                  Activation Fee quoted in the booking request. When setting your Activation Fee,
                  Activation Partners must use best endeavours to charge fair and reasonable
                  Activation Fees that reflect the value offered by a particular Activation having
                  regard to the nature of the Activation.
                </li>
                <li>
                  Any terms and conditions included in your Space Listing, in particular in relation
                  to cancellations, must not conflict with these Terms unless you provide
                  supplemental terms or special terms to the Brand.
                </li>
                <li>
                  Pictures, animations or videos (collectively, “Space Listing Images”) used in your
                  Space Listings must accurately reflect the quality and condition of your
                  Activations and Activation Spaces. Brandcrush reserves the right to require that
                  Space Listings have a minimum number of Images of a certain format, size and
                  resolution.
                </li>
                <li>
                  You must keep strictly confidential:
                  <ul>
                    <li>
                      all Activation Reports (except to the extent that you provide this information
                      to Brandcrush in accordance with these Terms);
                    </li>
                    <li>
                      prior to Activations, all materials related to Activations which identify a
                      Brand or which a Brand may be reasonably identified, including details of any
                      requests and correspondence made in connection with a Listing; and
                    </li>
                    <li>
                      all materials relating to an Activation which would be considered by a
                      reasonable person to be commercially sensitive or confidential in nature.
                    </li>
                  </ul>
                </li>
                <li>
                  The placement and ranking of Space Listings in search results on the Brandcrush
                  Platform may vary and depend on a variety of factors including Member search
                  parameters and preferences, Activation Partner requirements, price and
                  availability, number and quality of Space Listing Images, service and cancellation
                  history, Reviews and Ratings and type of Activation.
                </li>
                <li>
                  When you accept or have pre-approved a booking request by a Brand, you are
                  entering into a legally binding agreement with the Brand and are required to
                  provide the relevant Activation to the Brand as described in your Space Listing
                  when the booking request is made. You also agree to pay the applicable Commission
                  to Brandcrush and any applicable Taxes.
                </li>
                <li>
                  Brands and Activation Partners acknowledge that, in rare circumstances, the
                  success of an activation may be adversely affected by unforeseeable and/or
                  unavoidable circumstances, including circumstances beyond the reasonable control
                  of Activation Partners (for example; misplaced product; schedule changes) (
                  <b>Adverse Event</b>). If an Adverse Event occurs that may have a negative impact
                  on a Brand, Activation Partners must use best endeavours to:
                  <ol>
                    <li>
                      mitigate any loss and damage to the [brand] arising from the Adverse Event;
                      and
                    </li>
                    <li>arrange a “make good” campaign in consultation with the Brand.”</li>
                  </ol>
                </li>
              </ul>
            </p>
            <h3>4.2 Activation Reports</h3>
            <p>Where Activations are carried out on behalf of Brands by Activation Partners:</p>
            <p>
              <ul>
                <li>
                  Activation Partners must submit an Activation Report in the form prescribed by
                  Brandcrush as soon as practicable, and no later than 10 Business days after
                  completion of an Activation. Release by Brandcrush of the Activation Fee payable
                  in connection with the Activation is contingent on Brandcrush receiving an
                  Activation Report which proves the Activation was completed satisfactorily (as
                  determined by Brandcrush, acting reasonably); and
                </li>
                <li>
                  the Activation Partner will forfeit any Activation Fee payable in relation to an
                  Activation if an Activation Report has not been received by Brandcrush within 10
                  Business Days.
                </li>
              </ul>
            </p>
            <h3>4.3 Images</h3>
            <p>
              <ul>
                <li>
                  You grant Brandcrush and each Brand which books an Activation, a non-exclusive,
                  worldwide, royalty-free, irrevocable, perpetual (or for the term of the
                  protection), sub-licensable and transferable license to any Images contained in
                  the applicable Activation Report (<b>“Activation Report Images”</b>) to access,
                  use, store, copy, modify, prepare derivative works of, distribute, publish,
                  transmit, stream, broadcast, and otherwise exploit in any manner such Activation
                  Report Images to, in respect of Brandcrush, promote the Brandcrush Platform on
                  social media platforms and in respect of the Brand, to promote the Brand on social
                  media platforms.
                </li>
                <li>
                  You must ensure that you obtain the consent of any person who is identifiable in
                  the Activation Report Images in accordance with the following guidelines:
                  <ul>
                    <li>
                      if the person is clearly identifiable and/or the Image will, or may be, the
                      subject of an extensive or prominent marketing campaign, you must obtain the
                      written consent and release signed by the relevant person; and
                    </li>
                    <li>
                      if the person is not clearly identifiable and/or not the main subject of the
                      Image, or the image use is restricted to social media, you must obtain verbal
                      or implied consent of the relevant person.
                    </li>
                  </ul>
                </li>
                <li>
                  It is the sole responsibility of the Activation Partner to obtain, and be in the
                  position to verify and prove that the relevant consents have been obtained, in
                  connection with the Activation Report Images. By submitting the Activation Report
                  Images to Brandcrush, you represent and warrant that such consents have been
                  obtained.
                </li>
              </ul>
            </p>
            <h3>4.4 Exclusivity</h3>
            <p>
              In order for Brandcrush to ensure space inventory is managed and Brands have a
              satisfactory user experience, Activation Partners must not use any third party
              intermediary platform aside from the Brandcrush Platform to list activation spaces for
              booking by third parties. This clause 4.4 does not prevent Activations Partners
              booking activation spaces through their own channels which are not in the form of an
              online/marketplace platform.
            </p>
            <h3>4.5 Customer Reach Estimate</h3>
            <p>
              In addition to providing a fair and accurate Market Reach Estimate as a condition of
              your registration as an Activation Partner, you must also provide a fair and accurate
              assessment of your customer reach in respect of an Activation Space, as calculated
              having regard to the number of products/samples/messages which can be distributed to
              consumers/members/people per day/activation in a particular Activation Space.
            </p>
            <h3>4.6 Receipt of samples, materials and creative assets</h3>
            <p>
              <ul>
                <li>
                  Prior to the asset delivery dates (defined in clause 5.3(i) below), you will
                  receive an email from Brandcrush requiring you to inform us whether you have
                  received the relevant samples, materials and creative assets required for a booked
                  Activation. You must respond promptly and accurately to the email.
                </li>
                <li>
                  You must use best endeavours to accommodate any request by a Brand to extend a
                  defined asset delivery date (defined in clause 5.3(i) below) or vary the date for
                  performance of an Activation in circumstances where there would not be any
                  unreasonable impact on your business costs or operations. The brand reserves the
                  right to change the activation in accordance with clause 6.3. The Activation
                  Partner also reserves the right to apply change fees in accordance with clause
                  6.3.
                </li>
                <li>
                  It is the responsibility of the Brand to ensure all activation assets are
                  delivered in a state which are fit for purpose and as intended for the activation
                  and by the required dates. While it is not the responsibility of the Activation
                  Partner to determine whether the activation assets are fit for purpose, you should
                  use best endeavours to notify the Brand of any issues if they are identified. If
                  corrective action is required, the brand reserves the right to change the
                  activation in accordance with clause 6.3. You also reserve the right to apply
                  change fees in accordance with clause 6.3.
                </li>
                <li>
                  Where an Activation is to be activated by the Activation Partner, you must deliver
                  the relevant samples, materials and/or creative assets (<b>“Activation assets”</b>
                  ) to the Activation Partner by the date specified in the campaign workflows,
                  delivery csv file or purchase orders (<b>“asset delivery dates”</b>).
                </li>
                <li>
                  If brands fail to deliver <b>Activation assets</b> by the{" "}
                  <b>asset delivery dates</b> brands should contact you via the message service and
                  request that you accept delivery of the Activation assets at a later date. You may
                  also request that the Activation be moved to a later date. The Activation Partner
                  may, but has no obligation to, accept requests for extensions to the{" "}
                  <b>asset delivery dates</b> and/or variations to the Activation Date. The brand
                  reserves the right to change the activation in accordance with clause 6.3. The
                  Activation Partner also reserves the right to apply change fees in accordance with
                  clause 6.3.
                </li>
                <li>
                  If you are unable to change or negotiate an extension to the{" "}
                  <b>asset delivery dates</b> or a variation to the Activation Date, you will be
                  deemed to have cancelled the Activation and Service Fees will be charged in
                  accordance with clause 6.3.
                </li>
                <li>
                  For activations that require delivery of Products, the brand shall notify
                  Activation Partner of any product shortage or delay within 48 hours of the
                  scheduled product delivery date. If the brand fails to timely fulfill the volume
                  commitment stipulated in the booking, the Activation Partner shall not be
                  obligated to activate with the Product. The Brand shall be liable for all
                  incremental costs and shipping costs to replace Product or return the Product.
                </li>
                <li>
                  For activations that require delivery of Products as activation assets, if the
                  Product is received by the Activation Partner past the product due by date, the
                  Product shall be deemed late. If the product is received with a due by date that
                  does not allow for ample distribution time of the product for the activation
                  before the due by date, the product will be deemed as late. If you are unable to
                  change or negotiate an extension to the activation and asset delivery dates, you
                  will be deemed to have cancelled the Activation and Service Fees will be charged
                  in accordance with clause 6.3. If an activation date change is negotiated, you
                  shall be liable for all incremental costs and shipping costs to replace Product or
                  return the Product.
                </li>
                <li>
                  As freight issues can occur, we encourage Activation Partners to work with Brands
                  to accommodate a revised activation date if it can be accommodated with their
                  current operations.
                </li>
              </ul>
            </p>
            <h3>4.7 Food storage, handling and safety</h3>
            <p>
              Activation Partners offering Activations that involve the distribution of perishable
              products must ensure:
            </p>
            <p>
              <ul>
                <li>
                  the products are stored and handled in accordance with all relevant food safety
                  laws and guidelines and as marked on packaging; and
                </li>
                <li>
                  it holds all applicable licenses and certifications required by the relevant laws
                  and regulations (including any food handling certification of staff members in
                  contact with unpackaged products).
                </li>
              </ul>
            </p>
            <h3>4.8 Responsible service of alcohol</h3>
            <p>
              Activation Partners offering Activations that involve the service of alcohol or
              alcohol-related products must ensure:
            </p>
            <p>
              <ul>
                <li>
                  alcohol or alcohol-related products are only served, distributed or promoted to
                  those persons who meet the legal age for consumption of alcohol in the country in
                  which the Activation is taking place; and
                </li>
                <li>
                  it holds all applicable licenses and certifications required by the relevant laws
                  and regulations (including any responsible service of alcohol or other
                  certification required by staff members in contact with the products).
                </li>
              </ul>
            </p>
            <h3>4.9 Franchise agreements</h3>
            <p>
              If you create a Space Listing in respect of an Activation Space which is a franchise
              location, you represent and warrant that the Space Listing and any subsequent
              Activation complies with the applicable franchise agreement and any other terms,
              conditions or rules relating to the availability or authorized use of the Activation
              Space and the brand representation required by the relevant franchisor.
            </p>
            <h3>4.10 Mystery shoppers</h3>
            <p>
              Brandcrush may engage “mystery shoppers” or carry out other forms of random or
              targeted audits of Activations provided by Activation Partners in the marketplace.
            </p>
            <h3>4.11 Activation Partner Supplementary Terms</h3>
            <p>
              If an Activation Partner wishes to have terms and conditions apply to a Brand in
              respect of an Activation that are additional to, or in substitution of, these Terms of
              Service (<b>“Activation Partner Supplementary Terms”</b>), then the parties agree as
              follows:
            </p>
            <p>
              <ul>
                <li>
                  Brandcrush will execute the Activation Partner Supplementary Terms as authorised
                  agent of the Brand pursuant to clause 5.7 (<b>“Agent Execution Date”</b>).
                </li>
                <li>
                  Brandcrush will provide the Brand with a copy of the Activation Partner
                  Supplementary Terms (or otherwise inform the Brand of the key terms and conditions
                  of the Activation Partner Supplementary Terms) as soon as commercially practicable
                  following the Agent Execution Date.
                </li>
                <li>
                  The Brand will review the Activation Partner Supplementary Terms within 5 Business
                  Days of being provided with (or informed of) the Activation Partner Supplementary
                  Terms (<b>“Brand Review of Supplementary Terms”</b>).
                </li>
                <li>
                  Following the Brand Review of Supplementary Terms, the Brand will either:
                  <ul>
                    <li>
                      confirm acceptance of the Activation Partner Supplementary Terms (in which
                      case the Activation Partner Supplementary Terms will be treated as having
                      commenced on the Agent Execution Date); or
                    </li>
                    <li>
                      decline the Activation Partner Supplementary Terms (in which the Activation
                      Partner Supplementary Terms will be treated as void ab initio).
                    </li>
                  </ul>
                </li>
                <li>
                  Brandcrush will notify the Activation Partner of the outcome of the Brand Review
                  of Supplementary Terms as soon as commercially practicable after being provided
                  with that information.
                </li>
              </ul>
            </p>
          </section>
          <section id="brands">
            <h2>5. Terms specific to Brands</h2>
            <h3>5.1 Bookings and fees</h3>
            <p>
              Subject to meeting any requirements (such as completing any verification processes)
              set by Brandcrush and/or the Activation Partner, you can book a Space Listing
              available on the Brandcrush Platform by following the respective booking process. All
              applicable fees, including the Booking Fee, Activation Fee and any applicable Taxes
              (collectively, <b>“Total Fees”</b>) will be presented to you prior to booking a
              Listing. You agree to pay the Total Fees for any booking requested in connection with
              your Brandcrush Account. Brandcrush will collect the Total Fees at the time of the
              booking request.
            </p>
            <h3>5.2 Brand information</h3>
            <p>
              You must provide the Activation Partner with reasonable details of your brand
              (including logo, brand description, social handles and trade marks) to enable the
              Activation Partner to have an adequate representation of your brand in order to carry
              out the Activation.
            </p>
            <h3>5.3 Delivery of samples, materials and creative assets</h3>
            <p>
              <ul>
                <li>
                  Where an Activation is to be activated by the Activation Partner, you must deliver
                  at your cost the relevant samples, materials and/or creative assets (
                  <b>“Activation assets”</b>) to the Activation Partner by the date and to the
                  location agreed between the parties as specified in the campaign workflows,
                  delivery csv file or purchase orders (“
                  <b>asset delivery dates</b>”).
                </li>
                <li>
                  If you fail to deliver <b>Activation assets</b> by the <b>asset delivery dates</b>
                  , you should contact the Activation Partner via the message service and request
                  that they accept delivery of the Activation assets at a later date. You may also
                  request that the Activation be moved to a later date. The Activation Partner may,
                  but has no obligation to, accept requests for extensions to the{" "}
                  <b>asset delivery dates</b> and/or variations to the Activation Date. The brand
                  reserves the right to change the activation in accordance with clause 6.3. The
                  Activation Partner also reserves the right to apply change fees in accordance with
                  clause 6.3.
                </li>
                <li>
                  If you are unable to change or negotiate an extension to the{" "}
                  <b>asset delivery dates</b> or a variation to the Activation Date, you will be
                  deemed to have cancelled the Activation and Service Fees will be charged in
                  accordance with clause 6.3.
                </li>
                <li>
                  It is your responsibility to ensure all samples/assets are delivered in a state
                  which are fit for purpose and as intended for the activation. It is not the
                  responsibility of the Activation Partner to determine whether the samples/assets
                  are fit for purpose. Should corrective action be required, the brand reserves the
                  right to change the activation in accordance with clause 6.3. The Activation
                  Partner also reserves the right to apply change penalties in accordance with
                  clause 6.3.
                </li>
                <li>
                  For the avoidance of doubt, for activations that require delivery of Products as
                  activation assets, if the Product is received by the Activation Partner past the
                  product due by date, the Product shall be deemed late. If the product is received
                  with a due by date that does not allow for ample distribution time of the product
                  for the activation before the due by date, the product will be deemed as late. If
                  you are unable to change or negotiate an extension to the activation and asset
                  delivery dates, you will be deemed to have cancelled the Activation and
                  Service Fees will be charged in accordance with clause 6.3. If an activation date
                  change is negotiated, you shall be liable for all incremental costs and shipping
                  costs to replace Product or return the Product.
                </li>
              </ul>
            </p>
            <h3>5.4 Flag notification</h3>
            <p>
              Brands may use the “Flag this Activation Partner” function on the Brandcrush Platform
              to notify Brandcrush of any significant issues with a particular booked Activation or
              Activation Partner (for example, an Activation Space does not exist or is materially
              inconsistent with the relevant Listing). Where a Brand “flags” an Activation Partner,
              all Service Fees in connection with the booked Activation will be paused and any
              future bookings suspended pending investigation by Brandcrush. Brandcrush will use
              reasonable efforts to investigate the issue(s) and will make a determination in
              relation to payment of Service Fees. Brandcrush’s decisions in relation to disputes
              between Members will be final and binding. Brands can also email{" "}
              <a href="mailto:support@brandcrush.com">support@brandcrush.com</a> to advise of any
              issues.
            </p>
            <h3>5.5 General dispute resolution procedure</h3>
            <p>
              If a dispute arises out of or in connection with this Agreement or the performance,
              validity or enforceability of it (<b>Dispute</b>) and a Flag notification has not been
              raised in accordance with clause 5.4, then the parties must follow the procedure set
              out in this clause:
            </p>
            <p>
              <ul>
                <li>
                  either party shall give to the other written notice of the Dispute, setting out
                  its nature and full particulars (<b>Dispute Notice</b>), together with relevant
                  supporting documents. On service of the Dispute Notice, the Activation Partner and
                  Brand must attempt in good faith to resolve the Dispute;
                </li>
                <li>
                  if the Activation Partner and the Brand are for any reason unable to resolve the
                  Dispute within 10 days of service of the Dispute Notice, the Dispute shall be
                  referred to the relevant Activation Partner manager and their counterpart for the
                  Brand who shall attempt in good faith to resolve it; and
                </li>
                <li>
                  if the Activation Partner and Brand managers are unable to resolve the dispute,
                  the parties will refer to the arbitration process outlined in clause 17 of the
                  Terms of Service.
                </li>
              </ul>
            </p>
            <h3>5.6 Activation Partner Supplementary Terms</h3>
            <p>
              If an Activation Partner requires a Brand to agree to Activation Partner Supplementary
              Terms as a condition of an Activation in accordance with clause 4.11, then the parties
              agree as follows:
            </p>
            <p>
              <ul>
                <li>
                  The Brand hereby appoints Brandcrush as its duly authorised agent for the sole
                  purpose of executing Activation Partner Supplementary Terms on behalf of the Brand
                  (<b>“Agency Appointment”</b>).
                </li>
                <li>
                  The Brand acknowledges and agrees that any Activation Partner Supplementary Terms
                  will create a legal relationship between the Brand and the relevant Activation
                  Partner and Brandcrush will not be a party to the Activation Partner Supplementary
                  Terms.
                </li>
                <li>
                  Brandcrush agrees to provide the Brand with a copy of the Activation Partner
                  Supplementary Terms (or otherwise inform the Brand of the key terms and conditions
                  of the Activation Partner Supplementary Terms) as soon as commercially practicable
                  following the Agent Execution Date (as defined in clause 4.11).
                </li>
                <li>
                  The Brand must complete the Brand Review of Supplementary Terms (as defined in
                  clause 4.11) within 5 Business Days of being provided with, or informed of, the
                  Activation Partner Supplementary Terms (<b>“Review Period”</b>) and either:
                  <ul>
                    <li>
                      confirm acceptance of the Activation Partner Supplementary Terms (in which
                      case the Activation Partner Supplementary Terms will be treated as having
                      commenced on the Agent Execution Date); or
                    </li>
                    <li>
                      decline the Activation Partner Supplementary Terms (in which the Activation
                      Partner Supplementary Terms will be treated as void ab initio).
                    </li>
                  </ul>
                </li>
                <li>
                  If the Brand has not declined the Activation Partner Supplementary Terms before
                  expiration of the Review Period and the Brand continues to use the Brandcrush
                  Platform or take any action in relation to an Activation, the Brand will be deemed
                  to have accepted the Activation Partner Supplementary Terms.
                </li>
                <li>
                  Brandcrush will notify the Activation Partner of the outcome of the Brand Review
                  of Supplementary Terms as soon as commercially practicable after being provided
                  with that information.
                </li>
                <li>
                  The Brand may revoke the Agency Appointment by giving Brandcrush no less than 5
                  Business Days notice in writing.
                </li>
              </ul>
            </p>
            <h3>5.7 Non-exclusivity</h3>
            <p>
              You acknowledge and agree that the relationship between you and the Activation Partner
              is non-exclusive and Activation Partners may carry out Activations or otherwise
              promote brands which are similar to, or compete with, your brand. However,
              opportunities for effective or limited exclusivity exist where Brands and
              Activation Partners develop long term partnerships which may involve, for example,
              Brands booking out Activation Spaces for extended periods.
            </p>
            <h3>5.8 Legal agreement</h3>
            <p>
              Upon receipt of a booking confirmation from Brandcrush, a legally binding agreement is
              formed between you and the applicable Activation Partner, subject to any additional
              terms and conditions of the Activation Partner that apply, including in particular any
              Activation Partner Supplementary Terms or any rules and restrictions specified in the
              Listing. Any agreements created between a Brand and an Activation Partner are not
              binding on Brandcrush. We are not liable for, or obligated to enforce, any agreements
              between a Brand and an Activation Partner. You will not consider Brandcrush, nor will
              Brandcrush be construed as, a party to such transactions, whether or not Brandcrush
              receives some form of remuneration in connection with the transaction, and Brandcrush
              will not be liable for any costs or damages arising out of or related to such
              transaction.
            </p>
            <h3>5.9 Confidential Information</h3>
            <p>
              Brands and agencies including Authorized Third Parties (as defined in clause 3.5),
              must keep strictly confidential:
            </p>
            <p>
              <ul>
                <li>
                  All information relating to the Activation Partner’s 3PL providers, distribution
                  and warehousing, manufacturing, third party providers and contractors that may be
                  disclosed during the execution of the Activation
                </li>
                <li>
                  Prior to Activations, all materials related to Activations which identify an
                  Activation Partner or which an Activation Partner may be reasonably identified,
                  including details of any requests and correspondence made in connection with a
                  Listing; and
                </li>
                <li>
                  all materials relating to an Activation which would be considered by a reasonable
                  person to be commercially sensitive or confidential in nature.
                </li>
                <li>All information in relation to proposals, pricing and reach</li>
                <li>All sensitive campaign and product launch information</li>
                <li>
                  Any other sensitive information pertaining to the Activation Partners’s operations
                  that is not generally known to the public outside of the Brandcrush platform.
                </li>
              </ul>
            </p>
          </section>
          <section id="service-fees">
            <h2>6. Service &amp; Subscription Fees</h2>
            <h3>6.1 Fee types</h3>
            <p>
              Brandcrush may charge service &amp; subscription fees and commissions to Activations
              Partners (<b>“Activation Partner Fees”</b>) and/or Brands (<b>“Brand Fees”</b>)
              (collectively, <b>“Service Fees”</b>) in consideration for the use of the Brandcrush
              Platform.
            </p>
            <p>
              <ul>
                <li>
                  <b>Activation Partner Fees</b> comprise a commission payable by the Activation
                  Partner to Brandcrush (<b>“Commission”</b>) and is disclosed on the Activation
                  booking request or as otherwise set forth herein, online and/or in a separately
                  executed or accepted agreement between you and Brandcrush
                </li>
                <li>
                  <b>Brand Fees</b> comprise a fee payable by the Brand to Brandcrush (Booking Fee)
                  and the fee payable to the Activation Partner for the Activation (Activation Fee)
                  and are disclosed at checkout when the Brand makes a booking request.
                </li>
                <li>
                  <b>Subscription Fees</b> comprise an annual or monthly fee payable by the
                  Brand/Activation Partners to Brandcrush in consideration of utilizing Brandcrush
                  as a software as a service. Fees for the Subscription (“Subscription Fees”) are
                  set forth herein, online and/or in a separately executed or accepted agreement
                  between you and Brandcrush
                </li>
                <li>
                  Brandcrush may also from time to time provide certain services to Activation
                  Partners or Brands for an additional fee that would support Activation Partners
                  and Brands with their activations (<b>“Other Service Fees”</b>). These other
                  services may include but are not limited to freight, management and concierge
                  services. These fees will be disclosed either at checkout, online and/or in a
                  separately executed or accepted agreement between you and Brandcrush.
                </li>
              </ul>
            </p>
            <p>Except as otherwise specified in these Terms, Service Fees are non-refundable.</p>
            <h3>6.2 Timing of payments</h3>
            <p>
              <ul>
                <li>
                  Brand Fees are payable via EFT and ACH transfers and credit card payments via
                  third party payment gateway Stripe.
                </li>
                <li>
                  Payment terms and payment methods are communicated at the point of booking and via
                  auto generated invoices.
                </li>
                <li>
                  For credit card payments; The Brand Fees will be processed at the time that you
                  make a request to book an Activation (<b>“Activation Request”</b>). Brandcrush
                  will hold payment for a maximum of five (5) Business Days while your Activation
                  Request is pending. The Booking Fee will be released to Brandcrush on acceptance
                  of an Activation Request by the Activation Partner.
                </li>
                <li>
                  For brands approved for invoice payment: The Brand Fees will be issued in an
                  invoice and payment will be required to the nominated bank account on or before
                  the due date.
                </li>
                <li>
                  Brandcrush reserves the right to issue, deny or revoke credit terms from brands at
                  any time, and without prior notice.
                </li>
                <li>
                  Enterprise Activation Partners may opt to manage their own invoicing and
                  collections process. In this case, payment terms and payment methods are
                  communicated at the point of booking.
                </li>
                <li>
                  Unless Brandcrush becomes aware of an issue in relation to an Activation
                  (including where an issue has been “flagged” by a Brand in accordance with clause
                  5.4), the Activation Fee will be released to the Activation Partner to its
                  nominated Bank account:
                  <ul>
                    <li>
                      Payments will be made in the same currency as the activation fees charged. For
                      Payments made or transferred to a bank account which is not in the same
                      currency as the activation fees, currency conversion and transfer fees will be
                      deducted from the payment amount;
                    </li>
                    <li>
                      Payments that relate to marketplace bookings will be made within 45 business
                      days of a completed activation, unless otherwise stated in a separately
                      executed agreement.
                    </li>
                    <li>
                      Payments that relate to private media site bookings, will be made within 45
                      days from activation completed date unless otherwise stated in a separately
                      executed agreement or agreed in writing with Brandcrush and the partner.
                    </li>
                    <li>
                      The Activation completion date for Activations carried out on behalf of Brands
                      by Activation Partners is the date the Activation Report is completed
                    </li>
                    <li>
                      Where a payout is to be made to a Partner, it is the obligation of the
                      Partner, to ensure Payout account details are maintained in the platform and
                      are accurate.
                    </li>
                    <li>
                      Where a payout is to be made to a Partner, and the Partner fails to provide
                      payout account details within 180 days, Brandcrush reserves the right to cease
                      contacting the Partner in relation to the payout and may close out the payout
                      after a reasonable period of time (for clarity, any period longer than 2 years
                      will automatically be deemed to be a reasonable period of time) and the
                      Partner agrees not to take any action to recover the payout after this time.
                    </li>
                  </ul>
                </li>
                <li>
                  Where Activations are carried out on behalf of Brands by Activation Partners, an
                  activation is deemed to be completed when a valid proof of activation report has
                  been submitted.
                </li>
                <li>
                  Where Activations are carried out by the brand, an activation is deemed to be
                  completed when at the activation end date.{" "}
                </li>
                <li>
                  Where Activations are carried out on behalf of Brands by Activation Partners, the
                  Activation Partner will forfeit any right to payment of the Activation Fee in
                  relation to an Activation if an Activation Report has not been received by
                  Brandcrush within 10 Business Days.
                </li>
                <li>
                  Subscription fees:
                  <ul>
                    <li>
                      Unless otherwise communicated to you at the time of sign up via a separately
                      executed agreement or notification, each Subscription cycle is one-year in
                      length, with a minimum cancellation period of <b>12 months</b> or as stated on
                      a (<b>“Subscription Period”</b>), and will automatically renew on a yearly
                      basis until we terminate the Subscription, or you notify us by email to
                      cancelsubscription@brandcrush.com of your decision to terminate your current
                      Subscription; provided, however, that billing for subscription plans can only
                      be cancelled after a minimum of twelve consecutive monthly payments
                      (subscription cancellation period). Where there is a supplemental subscription
                      agreement, If there is a conflict between these Terms and any Supplemental
                      Terms, the Supplemental Terms will take precedence.
                    </li>
                    <li>
                      Unless otherwise stated, fees must be paid in advance of each billing period.
                      Your Subscription starts on the date that you sign up for a Subscription and
                      submit payment.
                    </li>
                    <li>Billing frequency can occur on a monthly or annual basis.</li>
                    <li>
                      For monthly billing, billing occurs on the same day each month, based on the
                      date that you started your subscription cycle (“Subscription Date”). Payments
                      are due for any month on the same or closest date to the day you made your
                      first monthly payment (the “Pay Date”). If any part of a month is included in
                      the Term, then payment is required for the full month.
                    </li>
                    <li>
                      If your Subscription begins on a date that does not occur every billing cycle
                      (e.g., the 31st), you will be billed on the last day of every month. We
                      reserve the right to change the timing of our billing.
                    </li>
                    <li>
                      You acknowledge that the amount billed to you each year (the subscription
                      period) may vary due to promotional offers and/or adding or changing your
                      Subscription plan, and you authorize Brandcrush to charge you for these
                      varying amounts as necessary.
                    </li>
                    <li>
                      Direct Debit Authorization: Brandcrush is authorized to direct debit
                      subscription payment from the nominated card or bank account during the course
                      of subscription period. It is the responsibility of the Activation Partner to
                      ensure sufficient funds are available at the beginning of each billing period.
                    </li>
                    <li>
                      Subscription fees are non-refundable and non-transferable except for as
                      expressly provided in these Terms of Service.
                    </li>
                  </ul>
                </li>
                <li>
                  If you fail to pay any charges or we are unable to successfully process your
                  payment of any charges within the timeframe required (as applicable), we reserve
                  the right to charge a late payment fee calculated at a rate of ten percent (10%)
                  per annum but no less than $10.00 per month. This amount represents a genuine and
                  reasonable estimate of such costs and expenses associated with managing and
                  processing late payments. We also reserve the right to withdraw credit facilities
                  (where applicable) at any time or to remove your access to the Brandcrush
                  Platform. You may also be liable to pay interest on any amount outstanding at the
                  applicable national interest rate on the date the payment becomes due and you may
                  also be responsible for all reasonable expenses incurred by us as a result of such
                  late payment (including but not limited to costs incurred by us to recover any
                  unpaid amounts). Without limiting our rights, in the event you fail to pay any
                  charges within the timeframe and in the manner required, we reserve the right to
                  suspend or terminate your access to the Brandcrush Platform or to suspend or
                  terminate any current campaigns.
                </li>
              </ul>
            </p>
            <h3>6.3 Changes, cancellations and refunds</h3>
            <p>
              <ul>
                <li>
                  Brands are entitled to make changes to an Activation. Change fees may apply, and
                  the change fees will be added to the Activation Fee.
                  <ul>
                    <li>
                      No change penalties apply for changes made at least thirty (30) days prior to
                      the first day of the applicable Activation Date
                    </li>
                    <li>
                      A 10% change fee will apply for changes made between fifteen (15) days and
                      twenty nine (29) days prior to the applicable Activation Date
                    </li>
                    <li>
                      A 20% change fee will apply for changes made less than fifteen (15) days prior
                      to the applicable Activation Date. The change fee will be added to the
                      Activation Fee
                    </li>
                    <li>
                      The Activation Partner reserves the right to waive these fees if they are not
                      disruptive to their operations.
                    </li>
                  </ul>
                </li>
                <li>
                  Service Fees payable by a Brand that cancels an Activation are non-refundable if
                  the Activation is cancelled less than fifteen (15) days prior to the first day of
                  the booked Activation (Activation Date).
                </li>
                <li>
                  A Brand may be entitled to a full or partial refund of the Service Fees payable by
                  the Brand in relation to an Activation cancelled by the Brand in accordance with
                  the following terms:
                  <ul>
                    <li>
                      the Brand will receive a refund of 100% of the Service Fees if the
                      cancellation is made at least thirty (30) days prior to the first day of the
                      applicable Activation Date; and
                    </li>
                    <li>
                      the Brand will receive a refund of 40% of the Service Fees (comprising 50% of
                      the Activation Fee, the Booking Fee will remain payable to Brandcrush) if
                      cancellation is made between fifteen (15) days and twenty nine (29) days prior
                      to the applicable Activation Date.
                    </li>
                  </ul>
                </li>
                <li>
                  A Brand will be entitled to a full refund of the Service Fees payable by the Brand
                  in relation to an Activation in the following circumstances:
                  <ul>
                    <li>the Activation is cancelled by the Activation Partner; or</li>
                    <li>
                      no Activation Report is received from the Activation Partner by Brandcrush in
                      relation to the Activation.
                    </li>
                  </ul>
                </li>
                <li>
                  An Activation Partner will be entitled to full or partial payment of the
                  Activation Fee payable by the Brand if a Brand cancels an Activation in the
                  following circumstances:
                  <ul>
                    <li>
                      50% of the Activation Fee will be payable where the cancellation is made
                      between fifteen (15) days and twenty nine (29) days prior to the applicable
                      Activation Date; and
                    </li>
                    <li>
                      100% of the Activation Fee will be payable where the cancellation is made less
                      than fifteen (15) days prior to the applicable Activation Date.
                    </li>
                  </ul>
                </li>
                <li>
                  Certain types of Activation Partners may also be subject to special terms and
                  conditions that override these Terms to the extent of any inconsistency. These
                  additional terms and conditions may be notified to you by any means (including by
                  email or by providing a link referring you to view an online document).
                </li>
                <li>
                  Subscription Fees: During your subscription term, subscription plans can only be
                  cancelled after a minimum of <b>twelve</b> consecutive monthly payments (
                  <b>“Cancellation Period”</b>). No early termination or refunds are available for
                  subscriptions paid on an annual billing period. For annual billing, the
                  Subscription Term will end on the expiration date and the subscription cannot be
                  cancelled early. We do not provide refunds if you decide to stop using the
                  Brandcrush subscription during your Subscription Term or Cancellation Period.
                  Where there is a supplemental subscription agreement, If there is a conflict
                  between these Terms and any Supplemental Terms, the Supplemental Terms will take
                  precedence.
                </li>
              </ul>
            </p>
            <h3>6.4 Payment by credit card</h3>
            <p>
              Brandcrush uses Stripe, Inc. (<b>“Stripe”</b>) as its third party service provider for
              payment services (e.g., card acceptance, merchant settlement, and related services).
              By using the Brandcrush Platform, you agree to be bound by Stripe’s Privacy Policy:
              https://www.stripe.com/privacy, and hereby consent and authorize Brandcrush and Stripe
              to share any information and payment instructions you provide with one or more third
              party service provider(s) to the minimum extent required to complete your
              transactions.
            </p>
            <p>
              If you are paying by credit card, you authorize us or Stripe to charge your credit
              card or bank account for all fees payable during the Subscription Term and/or for a
              booking.
            </p>
            <p>
              You will keep your contact information, billing information and credit card
              information (where applicable) up to date. Changes may be made on your Brandcrush
              Account. All payment obligations are non-cancelable and all amounts paid are
              non-refundable, except as specifically provided for in these Terms.
            </p>
            <h3>6.5 Payment by invoice</h3>
            <p>
              <ul>
                <li>
                  Invoice payments are only available to approved brands and agency partners. To
                  request approval, please email{" "}
                  <a href="mailto:accounts@brandcrush.com">accounts@brandcrush.com</a>.
                </li>
                <li>
                  Unless otherwise notified by Brandcrush in writing, invoices must be paid in full
                  on or prior to a date which is thirty (30) days after the invoice is issued by
                  Brandcrush. Any terms of credit offered by Brandcrush may be varied by Brandcrush
                  in its sole discretion by notifying you in writing.
                </li>
                <li>
                  Where Brandcrush approves a Member or Authorized Third Party (defined in clause
                  3.5(i) below) to pay by invoice, the Member or Authorized Third Party irrevocable
                  authorizes Brandcrush to make such inquiries as deemed necessary to investigate
                  the credit worthiness of the Member or Authorized Third Party’s (as the case may
                  be).
                </li>
              </ul>
            </p>
            <h3>6.6 Fee updates</h3>
            <p>
              Brandcrush may change the Service Fees at any time and will provide Members adequate
              notice of any fee changes before they become effective.
            </p>
            <p>
              Subscription fees will remain fixed during the Subscription Term. Outside of the
              Subscription Term, we reserve the right to adjust pricing for the Subscription
              Services at any time upon fourteen (14) days prior notice. Unless we expressly
              communicated otherwise, any price change to your membership will take effect on your
              next billing cycle. You must agree to the change in fees to continue to use the
              Services. To withdraw your consent, you will need to cancel your account by notifying
              us at{" "}
              <a href="mailto:cancelsubscription@brandcrush.com">
                cancelsubscription@brandcrush.com
              </a>
              .
            </p>
          </section>
          <section id="content">
            <h2>7. Content</h2>
            <h3>7.1 Member and Brandcrush content</h3>
            <p>Brandcrush may, at its sole discretion, enable Members to:</p>
            <p>
              <ul>
                <li>
                  create, upload, post, send, receive and store content, such as text, photos,
                  audio, video, or other materials and information on or through the Brandcrush
                  Platform (<b>“Member Content”</b>); and
                </li>
                <li>
                  access and view Member Content and any content that Brandcrush itself makes
                  available on or through the Brandcrush Platform, including proprietary Brandcrush
                  content and any content licensed or authorized for use by or through Brandcrush
                  from a third party (<b>“Brandcrush Content”</b>).
                </li>
              </ul>
            </p>
            <p>
              You acknowledge that Brandcrush has no obligation to pre-screen content (including,
              but not limited to, Member Content), although Brandcrush reserves the right in its
              sole discretion to pre-screen, refuse or remove any content. By entering into the
              Terms, you hereby provide your irrevocable consent to such monitoring. You acknowledge
              and agree that you have no expectation of privacy concerning the transmission of
              Member Content. In the event that Brandcrush pre-screens, refuses or removes any
              content, you acknowledge that Brandcrush will do so for Brandcrush’s benefit, not
              yours. Without limiting the foregoing, Brandcrush shall have the right to remove any
              content that violates the Terms or is otherwise objectionable.
            </p>
            <h3>7.2 Intellectual property rights </h3>
            <p>
              The Brandcrush Platform, Brandcrush Content, and Member Content may in its entirety or
              in part be protected by copyright, trademark, and/or other laws of United States of
              America, Australia and other countries. You acknowledge and agree that the Brandcrush
              Platform and Brandcrush Content, including all associated intellectual property
              rights, are the exclusive property of Brandcrush and/or its licensors or authorizing
              third parties. You will not remove, alter or obscure any copyright, trademark, service
              mark or other proprietary rights notices incorporated in or accompanying the
              Brandcrush Platform, Brandcrush Content or Member Content. All trademarks, service
              marks, logos, trade names, and any other source identifiers of Brandcrush used on or
              in connection with the Brandcrush Platform and Brandcrush Content are trademarks or
              registered trademarks of Brandcrush in the United States of America, Australia and/or
              in other countries. Trademarks, service marks, logos, trade names and any other
              proprietary designations of third parties used on or in connection with the Brandcrush
              Platform, Brandcrush Content, and/or Member Content are used for identification
              purposes only and may be the property of their respective owners.
            </p>
            <h3>7.3 Restrictions</h3>
            <p>
              You will not use, copy, adapt, modify, prepare derivative works of, distribute,
              license, sell, rent, lease, transfer, assign, reproduce, publicly display, publicly
              perform, transmit, broadcast or otherwise exploit the Brandcrush Platform, Brandcrush
              Content or Member Content, except to the extent you are the legal owner of certain
              Member Content or as expressly permitted in these Terms. No licenses or rights are
              granted to you by implication or otherwise under any intellectual property rights
              owned or controlled by Brandcrush or its licensors, except for the licenses and rights
              expressly granted in these Terms or which may be agreed to in writing by you,
              Brandcrush and the legal owner of the applicable Member Content.
            </p>
            <h3>7.4 Member licence grant</h3>
            <p>
              By creating, uploading, posting, sending, receiving, storing, or otherwise making
              available any Member Content on or through the Brandcrush Platform, you grant to
              Brandcrush a non-exclusive, worldwide, royalty-free, irrevocable, perpetual (or for
              the term of the protection), sub-licensable and transferable license to such Member
              Content to access, use, store, copy, modify, prepare derivative works of, distribute,
              publish, transmit, stream, broadcast, and otherwise exploit in any manner such Member
              Content to provide and/or promote the Brandcrush Platform, in any media or platform.
              Unless you provide specific consent, Brandcrush does not claim any ownership rights in
              any Member Content and nothing in these Terms will be deemed to restrict any rights
              that you may have to use or exploit your Member Content.
            </p>
            <p>
              Each Brand hereby grants each applicable Activation Partner a non-exclusive,
              non-transferable license to use the Brand’s trade names, trademarks, service marks,
              logos, domain names, and other distinctive brand features for the term of this
              Agreement in order to promote, advertise and display the relevant products on social
              media. The Brand warrants that it has the right to grant this licence.
            </p>
            <h3>7.5 Authorised Images</h3>
            <p>
              <ul>
                <li>
                  Brandcrush may offer Activation Partners the option of having professional
                  photographers take photographs of their Activations or Activation Spaces, which
                  are made available by the photographer to Activation Partners to include in their
                  Space Listings (<b>“Authorized Images”</b>). You are responsible for ensuring that
                  your Activation Space and Activations are accurately represented in the Authorized
                  Images and you will stop using the Authorized Images on or through the Brandcrush
                  Platform if they no longer accurately represent your Space Listing, if you stop
                  hosting the Activation featured, or if your Brandcrush Account is terminated or
                  suspended for any reason.
                </li>
                <li>
                  You acknowledge and agree that Brandcrush has the right to use any Authorized
                  Images for advertising, marketing and/or any other business purposes in any media
                  or platform, whether in relation to your Listing or otherwise, without further
                  notice or compensation to you. Where Brandcrush is not the exclusive owner of
                  Authorized Images, by using such Authorized Images on or through the Brandcrush
                  Platform, you grant to Brandcrush an exclusive, worldwide, royalty-free,
                  irrevocable, perpetual (or for the term of the protection), sub-licensable and
                  transferable license to use such Authorized Images for advertising, marketing
                  and/or any other business purposes in any media or platform, whether in relation
                  to your Listing or otherwise, without further notice or compensation to you.
                  Brandcrush in turn grants you a limited, non-exclusive, non-sublicensable,
                  revocable, non-transferable license to use Authorized Images outside of the
                  Brandcrush Platform solely on your social media channels in connection with your
                  legitimate business activities.
                </li>
              </ul>
            </p>
            <h3>7.6 Member content representations</h3>
            <p>
              You are solely responsible for all Member Content that you make available on or
              through the Brandcrush Platform. Accordingly, you represent and warrant that:
            </p>
            <p>
              <ul>
                <li>
                  you either are the sole and exclusive owner of all Member Content that you make
                  available on or through the Brandcrush Platform or you have all rights, licenses,
                  consents and releases that are necessary to grant to Brandcrush the rights in and
                  to such Member Content, as contemplated under these Terms; and
                </li>
                <li>
                  neither the Member Content nor your posting, uploading, publication, submission or
                  transmittal of the Member Content or Brandcrush's use of the Member Content (or
                  any portion thereof) will infringe, misappropriate or violate a third party's
                  patent, copyright, trademark, trade secret, moral rights or other proprietary or
                  intellectual property rights, or rights of publicity or privacy, or result in the
                  violation of any applicable law or regulation.
                </li>
              </ul>
            </p>
            <p>
              Brandcrush may, but is not obligated to, monitor or review Member Content at any time.
              Without limiting the foregoing, Brandcrush shall have the right, in its sole
              discretion, to remove any Member Content for any reason (or no reason), including if
              such Member Content violates the Terms or any applicable law. Although Brandcrush does
              not generally monitor user activity occurring in connection with Member Content, if
              Brandcrush becomes aware of any possible violations by you of any provision of the
              Terms, Brandcrush reserves the right to investigate such violations, and Brandcrush
              may, at its sole discretion, immediately terminate your license to use Services, or
              change, alter or remove Member Content, in whole or in part, without prior notice to
              you.
            </p>
            <h3>7.7 Prohibited content</h3>
            <p>You will not post, upload, publish, submit or transmit any Member Content that:</p>
            <p>
              <ul>
                <li>
                  is fraudulent, false, misleading (directly or by omission or failure to update
                  information) or deceptive;
                </li>
                <li>
                  is unlawful, tortious, defamatory, libelous, obscene, pornographic, vulgar or
                  offensive;
                </li>
                <li>
                  violates or encourages any conduct that would violate any applicable law or
                  regulation or would give rise to civil liability;
                </li>
                <li>
                  promotes discrimination, bigotry, racism, hatred, harassment or harm against any
                  individual or group;
                </li>
                <li>
                  is violent or threatening or promotes violence or actions that are threatening to
                  any other person or animal;
                </li>
                <li>violates any Brandcrush policy.</li>
              </ul>
            </p>
            <p>
              Brandcrush may, without prior notice, remove or disable access to any Member Content
              that Brandcrush finds to be in violation of these Terms or Brandcrush’s
              then-current policies or otherwise may be harmful or objectionable to Brandcrush, its
              Members, third parties, or property.
            </p>
            <h3>7.8 Feedback</h3>
            <p>
              You agree that submission of any ideas, suggestions, documents, and/or proposals to
              Brandcrush through its suggestion, feedback, wiki, forum or similar pages (
              <b>“Feedback”</b>) is at your own risk and that Brandcrush has no obligations
              (including without limitation obligations of confidentiality) with respect to such
              Feedback. You represent and warrant that you have all rights necessary to submit the
              Feedback. You hereby grant to Brandcrush a fully paid, royalty-free, perpetual,
              irrevocable, worldwide, non-exclusive, and fully sublicensable right and license to
              use, reproduce, perform, display, distribute, adapt, modify, re-format, create
              derivative works of, and otherwise commercially or non-commercially exploit in any
              manner, any and all Feedback, and to sublicense the foregoing rights, in connection
              with the operation and maintenance of the Brandcrush Platform and/or Brandcrush’s
              business.
            </p>
            <h3>7.9 Procedure for making claims of copyright infringement</h3>
            <p>
              It is Brandcrush’s policy to terminate membership privileges of any Member with a
              Brandcrush Account who repeatedly infringes copyright upon prompt notification to
              Brandcrush by the copyright owner or the copyright owner’s legal agent. Without
              limiting the foregoing, if you believe that your work has been copied and posted on
              Services in a way that constitutes copyright infringement, please provide our
              Copyright Agent with the following information: (a) an electronic or physical
              signature of the person authorized to act on behalf of the owner of the copyright
              interest; (b) a description of the copyrighted work that you claim has been infringed;
              (c) a description of the location on Services of the material that you claim is
              infringing; (d) your address, telephone number and e-mail address; (e) a written
              statement by you that you have a good faith belief that the disputed use is not
              authorized by the copyright owner, its agent or the law; and (f) a statement by you,
              made under penalty of perjury, that the above information in your notice is accurate
              and that you are the copyright owner or authorized to act on the copyright owner’s
              behalf. Contact information for Brandcrush’s Copyright Agent for notice of claims of
              copyright infringement is as follows: Brandcrush Pty Ltd, Attn: Copyright Agent, L4,
              80 Market St, South Melbourne, Victoria, Australia, 3042.
            </p>
          </section>
          <section id="insurance">
            <h2>8. Insurance</h2>
            <p>
              Given the nature of Activations and the interface with consumers and the general
              public, Brandcrush considers it is in all parties’ interests to ensure both
              Activations Partners and Brands have adequate insurance coverage. We expect Members
              will have the following insurance arrangements in place as part of their ordinary
              business practices in their respective regions of operation.
            </p>
            <h3>8.1 Activation Partners</h3>
            <p>Activation Partners must maintain throughout the term of this Agreement:</p>
            <p>
              <ul>
                <li>
                  commercial general liability insurance, written on an occurrence basis, on a
                  combined single limit of AUD$10,000,000 per occurrence; and
                </li>
                <li>statutory workers' compensation insurance.</li>
              </ul>
            </p>
            <h3>8.2 Brands</h3>
            <p>
              Brands must maintain through the term of this Agreement public liability insurance
              (incorporating product liability insurance), written on an occurrence basis, on a
              combined single limit of AUD$10,000,000 per occurrence. Note some Activation Partners
              may request a higher limit.
            </p>
            <h3>8.3 Coverage and certificates of currency</h3>
            <p>
              The primary insured (i.e. the Activation Partner or the Brand, as the case may be)
              must ensure that the other party to the Activation (<b>“Secondary Insured Party”</b>)
              is covered by the commercial general liability insurance or public liability insurance
              (as the case may be) and provide the Secondary Insured Party at least thirty (30)
              days' prior notice of any material amendment, cancellation or termination. Copies of
              the certificates of currency must be provided on request to the Secondary Insured
              Party.
            </p>
            <h3>8.4 Responsibility</h3>
            <p>
              It is the sole responsibility of the Secondary Insured Party to review the insurance
              arrangements of the other party for adequacy and compliance with this clause 8. In
              particular, Members are encouraged to read and understand and provisions relating to
              exclusions and deductions in connection with all insurance policies.
            </p>
            <h3>8.5 Concurrent insurance policies</h3>
            <p>
              If there are multiple insurance policies that provide concurrent coverage in respect
              of a particular occurrence or liability, the parties agree as follows:
            </p>
            <p>
              <li>
                Unless otherwise agreed between the parties, the policy (or policies) under which
                the party at fault is the primary insured should be claimed under as a first
                priority.
              </li>
              <li>
                A Brand or Activation Partner must not take any steps to claim under Brandcrush’s
                insurance policies without having first used best endeavours to claim under an
                insurance policy (or policies) under which it and the other party (i.e. the Brand or
                the Activation Partner, as the case may be) is either a primary insured or a
                Secondary Insured Party.
              </li>
              <li>
                Without derogating from the other provisions of this clause, a Brand or Activation
                Partner’s ability to claim under Brandcrush’s insurance policies is limited to the
                amount that Brandcrush is able to recoup from the party at fault (whether by way of
                direct payment of compensation or damages or indirectly by way of subrogation).
              </li>
            </p>
          </section>
          <section id="ratings">
            <h2>9. Ratings and Reviews</h2>
            <h3>9.1 Brand reviews and ratings</h3>
            <p>
              Within a certain timeframe after receipt of an Activation Report, Brands can leave a
              public review and/or submit a star rating about an Activation (collectively, the{" "}
              <b>“Ratings and Reviews”</b>). Ratings and Reviews reflect the opinions of individual
              Members and do not reflect the opinion of Brandcrush. Ratings and Reviews are not
              verified by Brandcrush for accuracy and may be incorrect or misleading. You
              acknowledge that any opinions, statement, recommendation, ratings, offers, advice or
              other information presented or disseminated in any Rating or Review are those of their
              respective authors who are solely responsible and liable for their content.
            </p>
            <h3>9.2 Rules</h3>
            <p>
              Brands must ensure its Ratings and Reviews are fair, reasonable and accurate and must
              not contain any offensive or defamatory language or otherwise breach Brandcrush’s
              House Rules and other policies.
            </p>
            <p>
              Brandcrush reserves the right to refuse to post or remove any material submitted or
              posted in any Ratings or Reviews in the event such Ratings or Reviews contain such
              offensive or defamatory language or otherwise breach Brandcrush’s House Rules and
              other policies.
            </p>
            <h3>9.3 Manipulation </h3>
            <p>
              Brands are prohibited from manipulating the Ratings and Reviews system in any manner,
              such as instructing a third party to write a positive or negative Review about an
              Activation Partner. Because we expect Members to maintain a high level of integrity
              with respect to Ratings and Reviews posted through the Brandcrush Platform, you agree:
              (i) to base any Rating or Review you post only on your first-hand experience with an
              Activation Partner; (ii) you will not provide a rating or review for an Activation
              Partner if you have an employment relationship or other affiliation with such
              Activation Partner; (iii) you will not submit a Rating or Review in exchange for
              payment or other benefits from any individual or entity; and (iv) your Review will
              comply with the terms of these Terms of Service. If Brandcrush determines, in our sole
              discretion, that any Rating or Review could diminish the integrity of the Ratings and
              Reviews, we may exclude such content without notice.
            </p>
            <h3>9.4 Public profile </h3>
            <p>
              Ratings and Reviews are part of an Activation Partner’s public profile and may appear
              throughout the Brandcrush Platform (for example, a Listing page) together with other
              relevant information such as number of bookings, number of cancellations, average
              response time and other information.
            </p>
          </section>
          <section id="taxes">
            <h2>10. Taxes</h2>
            <h3>10.1 GST, VAT and other Local Taxes</h3>
            <p>
              <ul>
                <li>
                  Expressions set out in italics in this clause 10.1 bear the same meaning as those
                  expressions in the A New Tax System (Goods and Services Tax) Act 1999, Value Added
                  Tax Act 1994 (Cth).
                </li>
                <li>
                  All Service Fees are tax exclusive, unless otherwise expressly stated. Tax, if
                  applicable, will be added as separate line items to the amount payable by the
                  Member.
                </li>
                <li>
                  To the extent that a party makes a taxable supply under or in connection with
                  these Terms, except where express provision is made to the contrary, the
                  consideration payable by a party under or in connection with these terms and
                  conditions represents the value of the taxable supply for which payment is to be
                  made and on which tax is to be calculated.
                </li>
                <li>
                  If a party makes a taxable supply under or in connection with these Terms for a
                  consideration, which, under clause 10.1(iii) represents its value, then the party
                  liable to pay for the taxable supply must also pay at the same time and in the
                  same manner as the value is otherwise payable, the amount of any tax payable in
                  respect of the taxable supply.
                </li>
              </ul>
            </p>
            <h3>10.2 Activation Fees</h3>
            <p>
              Except for GST or VAT, Activation Partners are solely responsible for determining
              their obligations to report, collect, remit or include in the Activation Fee any
              applicable indirect sales taxes, income taxes or other taxes (<b>“Taxes”</b>).
            </p>
            <h3>10.3 Reporting and withholding</h3>
            <p>
              Tax regulations may require us to collect Tax information from Activation Partners
              and/or to withhold Taxes from payouts to Activation Partners. If an Activation Partner
              fails to provide us with documentation that we determine to be sufficient to alleviate
              our obligation (if any) to withhold Taxes from payouts to you, we reserve the right to
              freeze all payouts and/or withhold such amounts as required by law until the matter is
              resolved.
            </p>
          </section>
          <section id="prohibited">
            <h2>11. Prohibited Activities</h2>
            <h3>11.1 Compliance with laws</h3>
            <p>
              You are solely responsible for compliance with any and all laws, rules, regulations,
              and tax obligations that may apply to your use of the Brandcrush Platform. In
              connection with your use of the Brandcrush Platform, you will not and will not assist,
              enable or encourage others to:
            </p>
            <p>
              <ul>
                <li>
                  misuse, misappropriate or otherwise use samples for a purpose other than as agreed
                  in connection with an Activation (including by distributing samples to friends and
                  family or people not situated in an Activation Space at the time of the
                  Activation);
                </li>
                <li>
                  not use best efforts to carry out an Activation to the highest possible standard
                  (taking into account the nature and agreed parameters of the Activation and
                  available resources);
                </li>
                <li>
                  use the Brandcrush Platform to request, make or accept a booking independent of
                  the Brandcrush Platform or to circumvent any Service Fees or for any other reason;
                </li>
                <li>
                  breach or circumvent any applicable laws or regulations, agreements with
                  third-parties, third-party rights, or our Terms, House Rules and other policies or
                  standards;
                </li>
                <li>
                  use the Brandcrush Platform, Brandcrush Content or Member Content for any
                  commercial or other purposes that are not expressly permitted by these Terms or in
                  a manner that falsely implies Brandcrush endorsement, partnership or otherwise
                  misleads others as to your affiliation with Brandcrush;
                </li>
                <li>
                  copy, store or otherwise access or use any information, including personally
                  identifiable information about any other Member, contained on the Brandcrush
                  Platform in any way that is inconsistent with Brandcrush’s Privacy Policy or these
                  Terms or that otherwise violates the privacy rights of Members or third parties;
                </li>
                <li>
                  use the Brandcrush Platform in connection with the distribution of unsolicited
                  commercial messages;
                </li>
                <li>
                  offer, as an Activation Partner, any Activation Space that you do not yourself own
                  or have permission to make available through the Brandcrush Platform;
                </li>
                <li>
                  unless Brandcrush explicitly permits otherwise, book any Activation if you will
                  not be using the Activation yourself;
                </li>
                <li>
                  contact another Member for any purpose other than asking a question related to a
                  your own booking, Listing, or the Member's use of the Brandcrush Platform,
                  including, but not limited to, attempting to negotiate terms or payment between
                  Members outside of the Brandcrush Platform, recruiting or otherwise soliciting any
                  Member to join third-party services, applications or websites, without our prior
                  written approval;
                </li>
                <li>
                  request, accept or make any payment for Service Fees outside of the Brandcrush
                  Platform or. If you do so, you acknowledge and agree that you:
                  <ul>
                    <li>would be in breach of these Terms;</li>
                    <li>accept all risks and responsibility for such payment; and</li>
                    <li>hold Brandcrush harmless from any liability for such payment;</li>
                  </ul>
                </li>
                <li>
                  discriminate against or harass anyone on the basis of race, national origin,
                  religion, gender, gender identity, physical or mental disability, medical
                  condition, marital status, age or sexual orientation, or otherwise engage in any
                  violent, harmful, abusive or disruptive behavior;
                </li>
                <li>
                  use, display, mirror or frame the Brandcrush Platform, Brandcrush Content or
                  Member Content, or any individual element within the Brandcrush Platform,
                  Brandcrush's name, any Brandcrush trademark, logo or other proprietary
                  information, or the layout and design of any page or form contained on a page in
                  the Brandcrush Platform, without Brandcrush's express written consent;
                </li>
                <li>
                  dilute, tarnish or otherwise harm the Brandcrush brand in any way, including
                  through unauthorized use of Brandcrush Content and Member Content, registering
                  and/or using Brandcrush or derivative terms in domain names, trade names,
                  trademarks or other source identifiers, or registering and/or using domains names,
                  trade names, trademarks or other source identifiers that closely imitate or are
                  confusingly similar to Brandcrush domains, trademarks, taglines, promotional
                  campaigns or Brandcrush Content and Member Content;
                </li>
                <li>
                  use any robots, spider, crawler, scraper or other automated means or processes to
                  access, collect data or other content from or otherwise interact with the
                  Brandcrush Platform for any purpose;
                </li>
                <li>
                  avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to
                  circumvent any technological measure implemented by Brandcrush or any of
                  Brandcrush's providers or any other third party to protect the Brandcrush
                  Platform;
                </li>
                <li>
                  attempt to decipher, decompile, disassemble or reverse engineer any of the
                  software used to provide the Brandcrush Platform;
                </li>
                <li>
                  take any action that damages or adversely affects, or could damage or adversely
                  affect the performance or proper functioning of the Brandcrush Platform;
                </li>
                <li>
                  export, re-export, import, or transfer the Application except as authorized by the
                  laws of your jurisdiction, and any other applicable laws; or
                </li>
                <li>violate or infringe anyone else’s rights or otherwise cause harm to anyone.</li>
              </ul>
            </p>
            <h3>11.2 Monitoring</h3>
            <p>
              You acknowledge that Brandcrush may, but has no obligation to, monitor the access to,
              or use of, the Brandcrush Platform by any Member or to review, disable access to, or
              edit any Member Content for purposes which include to:
            </p>
            <p>
              <ul>
                <li>
                  operate, secure and improve the Brandcrush Platform (including without limitation
                  for fraud prevention, risk assessment, investigation and customer support
                  purposes);
                </li>
                <li>ensure Members’ compliance with these Terms;</li>
                <li>
                  comply with applicable law or the order or requirement of a court, law enforcement
                  or other administrative agency or governmental body;
                </li>
                <li>
                  respond to Member Content that it determines is harmful or objectionable; or
                </li>
                <li>as otherwise set forth in these Terms.</li>
              </ul>
            </p>
            <p>
              Members agree to cooperate with and assist Brandcrush in good faith, and to provide
              Brandcrush with such information and take such actions as may be reasonably requested
              by Brandcrush with respect to any investigation undertaken by Brandcrush or a
              representative of Brandcrush regarding the use or abuse of the Brandcrush Platform.
            </p>
            <h3>11.3 Notification</h3>
            <p>
              If you feel that any Member you interact with, whether online or in person, is acting
              or has acted inappropriately, including but not limited to anyone who (i) engages in
              offensive, violent or sexually inappropriate behavior, (ii) you suspect of stealing
              from you, or (iii) engages in any other disturbing conduct, you should immediately
              report such person to the appropriate authorities and then to Brandcrush by contacting
              us with your police station and report number (if available). You agree that any
              report you make will not obligate us to take any action (beyond that required by law,
              if any).
            </p>
          </section>
          <section id="term">
            <h2>12. Term, termination and suspension</h2>
            <h3>12.1 Term</h3>
            <p>
              The Terms commence on the date when you accept them (as described in the preamble
              above) and remain in full force and effect while you use the Brandcrush Platform,
              unless terminated earlier in accordance with these Terms.
            </p>
            <h3>12.2 Termination</h3>
            <p>
              <ul>
                <li>
                  Unless otherwise stated in a separately executed agreement or subscription, you
                  may terminate this Agreement at any time by sending us an email. If you cancel
                  your Brandcrush Account as an Activation Partner, any confirmed booking(s) will be
                  automatically cancelled and your Brands will receive a full refund of the
                  Activation Fees. If you cancel your Brandcrush Account as a Brand, any confirmed
                  booking(s) will be automatically cancelled and any refund of Service Fees will be
                  determined in accordance with clause 6.3.
                </li>
                <li>
                  Unless otherwise communicated to you at the time of sign up via a separately
                  executed agreement or notification; if you choose to cancel your subscription
                  during the subscription period or any renewal period, provided that; we will not
                  provide any refunds and you will promptly pay all unpaid and remaining fees due
                  through to the end of the Subscription Period. We do not provide refunds if you
                  decide to stop using the Brandcrush subscription during your Subscription Period.
                  Payment of all fees shall become due immediately and payable on the date of
                  termination.
                </li>
                <li>
                  Brandcrush may terminate this Agreement for convenience at any time by giving you
                  thirty (30) days' notice via email to your registered email address.
                </li>
              </ul>
            </p>
            <h3>12.3 Termination or suspension for cause</h3>
            <p>
              Brandcrush may immediately, without notice, terminate this Agreement and/or stop
              providing access to the Brandcrush Platform if:
            </p>
            <p>
              <ul>
                <li>
                  you have materially breached your obligations under these Terms, our House Rules
                  or any of our policies or standards;
                </li>
                <li>you have violated applicable laws, regulations or third party rights; or</li>
                <li>
                  Brandcrush believes in good faith that such action is reasonably necessary to
                  protect the personal safety or property of Brandcrush, its Members, or third
                  parties (for example in the case of fraudulent behavior of a Member).
                </li>
              </ul>
            </p>
            <h3>12.4 Additional measures</h3>
            <p>
              Brandcrush may take any step or measure in connection with your Brandcrush Account and
              your use of the Brandcrush Platform (including deleting or delaying Space Listings,
              Ratings, Reviews, or other Member Content, cancelling any pending or confirmed
              Activations; limiting your access to or use of the Brandcrush Platform, temporarily or
              permanently revoke any special status associated with your Brandcrush Account and
              temporarily or permanently suspend your Brandcrush Account and stop providing access
              to the Brandcrush Platform):
            </p>
            <p>
              <ul>
                <li>
                  in order to comply with applicable law, or the order or request of a court, law
                  enforcement or other administrative agency or governmental body;
                </li>
                <li>
                  if you have breached these Terms, our House Rules, our policies or standards,
                  applicable laws, regulations, or third party rights;
                </li>
                <li>
                  if you have provided inaccurate, fraudulent, outdated or incomplete information
                  during the Brandcrush Account registration, Listing process or thereafter;
                </li>
                <li>
                  if you and/or your Space Listings or Activations at any time fail to meet any
                  applicable quality or eligibility criteria;
                </li>
                <li>
                  if you have repeatedly received poor Ratings or Reviews or Brandcrush otherwise
                  becomes aware of or has received complaints about your performance or conduct;
                </li>
                <li>
                  if you have repeatedly cancelled confirmed bookings or failed to respond to
                  booking requests without a valid reason; or
                </li>
                <li>
                  Brandcrush believes in good faith that such action is reasonably necessary to
                  protect the personal safety or property of Brandcrush, its Members, or third
                  parties, or to prevent fraud or other illegal activity:
                </li>
              </ul>
            </p>
            <p>
              In case of minor breaches and where appropriate, you will be given notice of any
              intended measure by Brandcrush and an opportunity to resolve the issue to Brandcrush's
              reasonable satisfaction.
            </p>
            <h3>12.5 Refund of Activation Fees</h3>
            <p>
              If we take any of the measures described above we may refund any Activation Fees
              otherwise payable by Brands in full for any and all confirmed Activations that have
              been cancelled and you will not be entitled to any compensation for pending or
              confirmed bookings that are cancelled.
            </p>
            <h3>12.6 New accounts</h3>
            <p>
              When this Agreement has been terminated, you are not entitled to a restoration of your
              Brandcrush Account or any of your Member Content. If your access to or use of the
              Brandcrush Platform has been limited or your Brandcrush Account has been suspended or
              this Agreement has been terminated by us, you may not register a new Brandcrush
              Account or access and use the Brandcrush Platform through an Brandcrush Account of
              another Member.
            </p>
            <h3>12.7 Survival</h3>
            <p>
              If you or we terminate this Agreement, the clauses of these Terms that reasonably
              should survive termination of the Agreement will remain in effect.
            </p>
          </section>
          <section id="interaction">
            <h2>13. Interaction with Other Members</h2>
            <h3>13.1 Member responsibility</h3>
            <p>
              You are solely responsible for your interactions with other Members and any other
              parties with whom you interact; provided, however, that Brandcrush reserves the right,
              but has no obligation, to intercede in such disputes. You agree that Brandcrush will
              not be responsible for any liability incurred as the result of such interactions.
            </p>
            <h3>13.2 Content provided by other members</h3>
            <p>
              Services may contain Member Content provided by other Members. Brandcrush is not
              responsible for and does not control Member Content. Brandcrush has no obligation to
              review or monitor, and does not approve, endorse or make any representations or
              warranties with respect to Member Content. You use all Member Content and interact
              with other Members at your own risk.
            </p>
          </section>
          <section id="indemnification">
            <h2>14. Indemnification</h2>
            <h3>14.1 Indemnity given to Brandcrush</h3>
            <p>
              You agree to release, defend (at Brandcrush’s option), indemnify, and hold Brandcrush
              and its affiliates and subsidiaries, including but not limited to officers, directors,
              employees and agents (collectively, the <b>“Brandcrush Parties”</b>), harmless from
              and against any claims, liabilities, damages, losses, and expenses, including, without
              limitation, reasonable legal and accounting fees, arising out of or in any way
              connected with:
            </p>
            <p>
              <ul>
                <li>
                  your breach of these Terms, the House Rules or any of our policies or standards;
                </li>
                <li>your improper use of the Brandcrush Platform;</li>
                <li>
                  your interaction with any Member or any participation in, or use of, any
                  Activation including without limitation any injuries, losses or damages (whether
                  compensatory, direct, incidental, consequential or otherwise) of any kind arising
                  in connection with or as a result of such interaction, participation or use; or
                </li>
                <li>your breach of any laws, regulations or third party rights.</li>
              </ul>
            </p>
            <h3>14.2 Indemnity given to Activation Partners</h3>
            <p>
              Each Brand shall indemnify the relevant Activation Partner against all liabilities,
              costs, expenses, damages and losses (including but not limited to any direct, indirect
              or consequential losses, loss of profit, loss of reputation and all interest,
              penalties and legal and other reasonable professional costs and expenses) suffered or
              incurred by the Activation Partner arising out of or in connection with:
            </p>
            <p>
              <li>
                any claim made against the Activation Partner for actual or alleged infringement of
                a third party’s intellectual property rights arising out of, or in connection with,
                the supply or use of the relevant products;
              </li>
              <li>
                any claim made against the Activation Partner by a third party arising out of, or in
                connection with, the supply of the relevant products, to the extent that such claim
                arises out of the breach, negligent performance or failure or delay in performance
                of this Agreement by the Brand, its employees, agents or subcontractors;
              </li>
              <li>
                any claim made against the Activation Partner by a third party for death, personal
                injury or damage to property arising out of, or in connection with, defective
                products, to the extent that the defect in the relevant products is attributable to
                the acts or omissions of the Brand, its employees, agents or subcontractors.
              </li>
            </p>
          </section>
          <section id="disclaimers">
            <h2>15. Disclaimers</h2>
            <p>
              <ul>
                <li>
                  If you choose to use the Brandcrush Platform, Brandcrush Content or Member
                  Content, you do so voluntarily and at your sole risk. The Brandcrush Platform,
                  Brandcrush Content or Member Content is provided “as is”, without warranty of any
                  kind, either express or implied.
                </li>
                <li>
                  You agree that you have had whatever opportunity you deem necessary to investigate
                  the Brandcrush Platform, laws, rules, or regulations that may be applicable to
                  your Space Listings and/or Activations you are booking or carrying out and that
                  you are not relying upon any statement of law or fact made by Brandcrush relating
                  to a Listing or Activation.
                </li>
                <li>
                  If we choose to conduct identity verification or background checks on any Member,
                  to the extent permitted by applicable law, we disclaim warranties of any kind,
                  either express or implied, that such checks will identify prior misconduct by a
                  Member or guarantee that a Member will not engage in misconduct in the future.
                </li>
                <li>
                  You agree that some Activations may carry inherent risk, and by participating or
                  procuring a Member to engage in such Activations, you choose to assume those risks
                  voluntarily.
                </li>
                <li>
                  You acknowledge and agree that Brandcrush Parties are not liable, and you agree
                  not to seek to hold Brandcrush Parties liable, for the conduct or omissions of
                  third parties, including any Members, and that the risk of injury from such third
                  parties rests entirely with you.
                </li>
                <li>
                  You are solely responsible for all of your interactions with other users of the
                  Brandcrush Platform. You understand that Brandcrush does not make any attempt to
                  verify the statements of Members. Brandcrush makes no representations or
                  warranties as to the conduct of users of the Brandcrush Platform. You agree to
                  take reasonable precautions in all interactions with other Members.{" "}
                </li>
                <li>
                  The foregoing disclaimers apply to the maximum extent permitted by law. You may
                  have other statutory rights. However, the duration of statutorily required
                  warranties, if any, shall be limited to the maximum extent permitted by law.
                </li>
              </ul>
            </p>
          </section>
          <section id="liability">
            <h2>16. Liability</h2>
            <h3>16.1 General</h3>
            <p>Subject to clause 16.3 and to the maximum extent permitted by law, you:</p>
            <p>
              <ul>
                <li>
                  acknowledge and agree that, to the maximum extent permitted by law, the entire
                  risk arising out of your access to and use of the Brandcrush Platform, Brand
                  Content and Member Content, your publishing or booking of any Listing via the
                  Brandcrush Platform, your participation in any Activation or any other interaction
                  you have with other Members whether in person or online remains with you;
                </li>
                <li>
                  agree to release and hold harmless Brandcrush Parties from all liabilities and
                  claims that arise in any way from any injury, death, loss or harm that occurs to
                  any person in connection with an Activation or in any way connected to the
                  Brandcrush Platform;
                </li>
                <li>
                  neither the Brandcrush Parties nor any other party involved in creating,
                  producing, or delivering the Brandcrush Platform Brandcrush Content and Member
                  Content will be liable for any incidental, special, exemplary or consequential
                  damages, including lost profits, loss of data or loss of goodwill, service
                  interruption, computer damage or system failure or the cost of substitute products
                  or services, or for any damages for personal or bodily injury or emotional
                  distress arising out of or in connection with:
                  <ul>
                    <li>these Terms;</li>
                    <li>
                      from the use of or inability to use the Brandcrush Platform, Brandcrush
                      Content or Member Content;
                    </li>
                    <li>
                      any communications, interactions or meetings with other Members or other
                      persons with whom you communicate, interact or meet with as a result of your
                      use of the Brandcrush Platform; or
                    </li>
                    <li>
                      your publishing or booking of a Listing, including the provision or use of an
                      Activation, whether based on warranty, contract, tort (including negligence),
                      product liability or any other legal theory, and whether or not Brandcrush has
                      been informed of the possibility of such damage, even if a limited remedy set
                      forth herein is found to have failed of its essential purpose.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
            <h3>16.2 Cap</h3>
            <p>
              Subject to clause 16.3 and except for Brandcrush’s obligations to pay amounts to
              Activation Partners pursuant to these Terms, in no event will Brandcrush’s aggregate
              liability arising out of or in connection with these Terms and your use of the
              Brandcrush Platform including, but not limited to, from your publishing or booking of
              any Space Listings via the Brandcrush Platform, or from the use of or inability to use
              the Brandcrush Platform, Brand Content or Member Content and in connection with any
              Activation or interactions with any other Members, exceed the following amounts:
            </p>
            <p>
              <ul>
                <li>
                  if you are an Activation Partner, the amounts paid by Brandcrush to you in the
                  twelve (12) month period prior to the event giving rise to the liability or $100
                  if no such payments have been made; or
                </li>
                <li>
                  if you are a Brand, the amounts you have paid or owe for bookings via the
                  Brandcrush Platform in the twelve (12) month period prior to the event giving rise
                  to the liability.
                </li>
              </ul>
            </p>
            <h3>16.3 Exclusions</h3>
            <p>
              Nothing in this Agreement shall limit or exclude the liability of any party for: (a)
              death or personal injury from negligence; (b) fraud or fraudulent misrepresentation;
              (c) breach of the terms implied by section 12 of the Sales of Goods Act 1979 (if
              applicable); (d) breach of section 2 of the Consumer Protection Act 1987 (if
              applicable); and (e) the indemnities contained in clause 14; or (f) the deliberate
              default or wilful misconduct of that party, its employees, agents or subcontractors.
            </p>
            <p>
              Without prejudice to the foregoing, the Activation Partner shall not be liable to the
              Brand whether in contract, tort (including negligence) or restitution, or for breach
              of statutory duty or misrepresentation, or otherwise, for any: (a) loss of profit; (b)
              loss of goodwill; (c) loss of business; (d) loss of business opportunity; (e) loss of
              anticipated saving; or (f) special, indirect or consequential damage or loss.
            </p>
            <p>
              Each Brand acknowledges that in the event that the nature of the relevant Activation
              Partner’s business is such that it relies on non-automated large scale packing of
              boxes, then the Activation Partner may make a reasonable amount of errors (for
              example: double packing flyers or samples into a single box, failure to put samples
              into boxes in the correct dispatch, or failure to put both sample and flyer in a box
              when required together) for which it will have no liability to Brand. Acceptable level
              of errors to be assessed on an objective basis by the Activation Partner at its sole
              discretion, taking into account the likelihood of human errors in non-automated
              processing.
            </p>
            <h3>16.4 Member Content</h3>
            <p>
              EXCEPT FOR BRANDCRUSH’S OBLIGATIONS TO PROTECT YOUR PERSONAL DATA AS SET FORTH IN THE
              BRANDCRUSH’S PRIVACY POLICY , BRANDCRUSH ASSUMES NO RESPONSIBILITY FOR THE TIMELINESS,
              DELETION, MIS-DELIVERY OR FAILURE TO STORE ANY CONTENT (INCLUDING, BUT NOT LIMITED TO,
              YOUR CONTENT AND MEMBER CONTENT), MEMBER COMMUNICATIONS OR PERSONALIZATION SETTINGS.
            </p>
            <h3>16.5 Basis of the Bargain</h3>
            <p>
              THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF
              THE BARGAIN BETWEEN BRANDCRUSH AND YOU.
            </p>
          </section>
          <section id="dispute">
            <h2>17. Dispute Resolution</h2>
            <p>
              <b>
                <em>
                  Please read the following arbitration agreement in this clause (“Arbitration
                  Agreement”) carefully. It requires you to arbitrate disputes with Brandcrush and
                  limits the manner in which you can seek relief from us.
                </em>
              </b>
            </p>
            <h3>17.1 Applicability of Arbitration Agreement</h3>
            <p>
              You agree that any dispute, claim, or request for relief relating in any way to your
              access or use of the Website, to any products sold or distributed through the Website,
              or to any aspect of your relationship with Brandcrush, will be resolved by binding
              arbitration, rather than in court, except that (1) you may assert claims or seek
              relief in small claims court if your claims qualify; and (2) you or Brandcrush may
              seek equitable relief in court for infringement or other misuse of intellectual
              property rights (such as trademarks, trade dress, domain names, trade secrets,
              copyrights, and patents).{" "}
              <b>
                This Arbitration Agreement shall apply, without limitation, to all disputes or
                claims and requests for relief that arose or were asserted before the effective date
                of these Terms or any prior version of these Terms.
              </b>
            </p>
            <h3>17.2 Arbitration Rules and Forum</h3>
            <p>
              The Federal Arbitration Act governs the interpretation and enforcement of this
              Arbitration Agreement. To begin an arbitration proceeding, you must send a letter
              requesting arbitration and describing your dispute or claim or request for relief to
              our registered agent: Brandcrush Pty Ltd, L4, 80 Market St, South Melbourne, Victoria,
              Australia. The arbitration will be conducted by JAMS established alternative dispute
              resolution provider or other alternative arbitral forum,. Disputes involving claims,
              counterclaims, or request for relief under $250,000, not inclusive of attorneys’ fees
              and interest, shall be subject to JAMS’s or alternative arbitral forum’s most current
              version of the Streamlined Arbitration Rules and procedures. All other disputes shall
              be subject to JAMS’s or alternative arbitral forum’s most current version of the
              Comprehensive Arbitration Rules and Procedures. If JAMS is not available to arbitrate,
              the parties will select an alternative arbitral forum. If the arbitrator finds that
              you cannot afford to pay JAMS’s or alternative arbitral forums filing, administrative,
              hearing and/or other fees and cannot obtain a waiver from JAMS, Brandcrush will pay
              them for you. In addition, Brandcrush will reimburse all such JAMS’s or alternative
              arbitral forums filing, administrative, hearing and/or other fees for disputes,
              claims, or requests for relief totaling less than $10,000 unless the arbitrator
              determines the claims are frivolous.{" "}
            </p>
            <p>
              You may choose to have the arbitration conducted by telephone, based on written
              submissions, or in person in the country where you live or at another mutually agreed
              location. Any judgment on the award rendered by the arbitrator may be entered in any
              court of competent jurisdiction.
            </p>
            <h3>17.3 Authority of Arbitrator</h3>
            <p>
              The arbitrator shall have exclusive authority to (a) determine the scope and
              enforceability of this Arbitration Agreement and (b) resolve any dispute related to
              the interpretation, applicability, enforceability or formation of this Arbitration
              Agreement including, but not limited to, any assertion that all or any part of this
              Arbitration Agreement is void or voidable. The arbitration will decide the rights and
              liabilities, if any, of you and Brandcrush. The arbitration proceeding will not be
              consolidated with any other matters or joined with any other cases or parties. The
              arbitrator shall have the authority to grant motions dispositive of all or part of any
              claim. The arbitrator shall have the authority to award monetary damages and to grant
              any non-monetary remedy or relief available to an individual under applicable law, the
              arbitral forum’s rules, and the Terms (including the Arbitration Agreement). The
              arbitrator shall issue a written award and statement of decision describing the
              essential findings and conclusions on which the award is based, including the
              calculation of any damages awarded. The arbitrator has the same authority to award
              relief on an individual basis that a judge in a court of law would have. The award of
              the arbitrator is final and binding upon you and us.
            </p>
            <h3>17.4 Waiver of Jury Trial</h3>
            <p>
              YOU AND BRANDCRUSH HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN
              COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and Brandcrush are instead
              electing that all disputes, claims, or requests for relief shall be resolved by
              arbitration under this Arbitration Agreement, except as specified in clause 17.1
              above. An arbitrator can award on an individual basis the same damages and relief as a
              court and must follow these Terms as a court would. However, there is no judge or jury
              in arbitration, and court review of an arbitration award is subject to very limited
              review.
            </p>
            <h3>17.5 Waiver of Class or Other Non-Individualized Relief</h3>
            <p>
              ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS ARBITRATION
              AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE
              BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR
              MEMBER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR
              MEMBER. If a decision is issued stating that applicable law precludes enforcement of
              any of this clause’s limitations as to a given dispute, claim, or request for relief,
              then such aspect must be severed from the arbitration and brought into the State or
              Federal Courts located in the State of Delaware. All other disputes, claims, or
              requests for relief shall be arbitrated.
            </p>
            <h3>17.6 30-Day Right to Opt Out</h3>
            <p>
              You have the right to opt out of the provisions of this Arbitration Agreement by
              sending written notice of your decision to opt out to: L4, 80 Market St, South
              Melbourne, Victoria, Australia, 3042 within 30 days after first becoming subject to
              this Arbitration Agreement. Your notice must include your name and address, your
              Brandcrush username (if any), the email address you used to set up your Brandcrush
              account (if you have one), and an unequivocal statement that you want to opt out of
              this Arbitration Agreement. If you opt out of this Arbitration Agreement, all other
              parts of these Terms will continue to apply to you. Opting out of this Arbitration
              Agreement has no effect on any other arbitration agreements that you may currently
              have, or may enter in the future, with us.
            </p>
            <h3>17.7 Severability</h3>
            <p>
              Except as provided in clause 17.5, if any part or parts of this Arbitration Agreement
              are found under the law to be invalid or unenforceable, then such specific part or
              parts shall be of no force and effect and shall be severed and the remainder of the
              Arbitration Agreement shall continue in full force and effect.
            </p>
            <h3>17.8 Survival of Agreement</h3>
            <p>
              This Arbitration Agreement will survive the termination of your relationship with
              Brandcrush.
            </p>
            <h3>17.9 Modification</h3>
            <p>
              Notwithstanding any provision in these Terms to the contrary, we agree that if
              Brandcrush makes any future material change to this Arbitration Agreement, you may
              reject that change within thirty (30) days of such change becoming effective by
              writing Brandcrush at the following address: L4, 80 Market St, South Melbourne,
              Victoria, Australia, 3042.
            </p>
          </section>
          <section id="general">
            <h2>18. General Provisions</h2>
            <h3>18.1 Entire Agreement</h3>
            <p>
              Except as they may be supplemented by additional terms and conditions, policies,
              guidelines or standards, these Terms constitute the entire Agreement between
              Brandcrush and you pertaining to the subject matter hereof, and supersede any and all
              prior oral or written understandings or agreements between Brandcrush and you in
              relation to the access to and use of the Brandcrush Platform.
            </p>
            <h3>18.2 No joint venture or partnership</h3>
            <p>
              No joint venture, partnership, employment, or agency relationship exists between you
              and Brandcrush or between Brands and Activation Partners as a result of this Agreement
              or your use of the Brandcrush Platform.
            </p>
            <h3>18.3 Force majeure</h3>
            <p>
              <ul>
                <li>
                  No party will be liable or responsible for a breach of these Terms which is caused
                  by a force majeure event (being an act which is not within the control of the
                  relevant party, including national emergencies, inclement weather, war and
                  terrorism) (Force Majeure Event) if the affected party:
                  <ul>
                    <li>
                      is unable to perform its obligations because of the Force Majeure Event;
                    </li>
                    <li>
                      promptly notifies Brandcrush and the Member(s) who may be affected by the
                      Force Majeure Event;
                    </li>
                    <li>
                      resumes performance of its obligations under these Terms as soon as reasonably
                      practicable.
                    </li>
                  </ul>
                </li>
                <li>
                  If Brandcrush is not capable of running the Brandcrush Platform as planned for any
                  reason including a Force Majeure Event, Brandcrush reserves the right, in its
                  absolute discretion, to cancel, terminate, modify or suspend the Brandcrush
                  Platform.
                </li>
              </ul>
            </p>
            <h3>18.4 Severability</h3>
            <p>
              If any provision of these Terms is held to be invalid or unenforceable, such provision
              will be struck and will not affect the validity and enforceability of the remaining
              provisions.
            </p>
            <h3>18.5 No waiver</h3>
            <p>
              Brandcrush’s failure to enforce any right or provision in these Terms will not
              constitute a waiver of such right or provision unless acknowledged and agreed to by us
              in writing. Except as expressly set forth in these Terms, the exercise by either party
              of any of its remedies under these Terms will be without prejudice to its other
              remedies under these Terms or otherwise permitted under law.
            </p>
            <h3>18.6 Assignment</h3>
            <p>
              You may not assign, transfer or delegate this Agreement and your rights and
              obligations hereunder without Brandcrush's prior written consent.
            </p>
            <h3>18.7 Electronic communications</h3>
            <p>
              Unless specified otherwise, any notices or other communications to Members permitted
              or required under this Agreement, will be provided electronically and given by
              Brandcrush via email, Brandcrush Platform notification, or messaging service. The date
              of receipt of the notice will be deemed the date on which Brandcrush transmits the
              notice.
            </p>
            <h3>18.8 Queries</h3>
            <p>
              If you have any questions about these Terms, please email us at{" "}
              <a href="mailto:support@brandcrush.com">support@brandcrush.com</a>.
            </p>
            <h3>18.9 Governing law and jurisdiction</h3>
            <p>
              THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND INTERPRETED BY AND
              UNDER:
            </p>
            <p>
              <ul>
                <li>
                  IF YOU ARE BASED IN THE UNITED STATES, THE LAWS OF THE STATE OF DELAWARE,
                  CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY
                  PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER JURISDICTION AND
                  WITHOUT REGARD TO THE UNITED NATIONS CONVENTION ON CONTRACTS.{" "}
                </li>
                <li>
                  IF YOU ARE BASED OUTSIDE OF THE UNITED STATES, THE LAWS OF VICTORIA, AUSTRALIA,
                  WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE
                  LAW OF ANOTHER JURISDICTION.
                </li>
                <li>
                  IN RESPECT OF CLAUSE 17 (ARBITRATION), IF YOU ARE AN ACTIVATION PARTNER BASED
                  OUTSIDE OF THE UNITED STATES AND THE RELEVANT DISPUTE IS BETWEEN AN ACTIVATION
                  PARTNER AND A BRAND ONLY, THEN YOU MAY CHANGE THE GOVERNING LAW AND JURISDICTION
                  TO A JURISDICTION OUTSIDE VICTORIA, AUSTRALIA WITH BRANDCRUSH’S WRITTEN CONSENT.
                </li>
              </ul>
            </p>
            <p>
              To the extent the parties are permitted under these Terms to initiate litigation in a
              court, both you and Brandcrush agree that all claims and disputes arising out of or
              relating to the Terms will be litigated in:
            </p>
            <p>
              <ul>
                <li>
                  If you are based in the United States, the state or federal courts located in the
                  State of Delaware.
                </li>
              </ul>
            </p>
            <p>
              If you are based outside the United States, the courts and tribunals located in
              Victoria, Australia.
            </p>
          </section>
          <section id="brandcrush">
            <h2>19. BRANDCRUSH - HOUSE RULES</h2>
            <h3>HOUSE RULES | BRAND</h3>
            <p>
              BE RELEVANT: Brandcrush is here to help build loyal relationships between Activation
              Partners, brands and their shared audiences through meaningful and relevant moments of
              connection. Request to activate in spaces that are aligned to your brand.
            </p>
            <p>
              BE ACCURATE: Your activation description needs to be representative of what is going
              to happen in real life. Describe your activation and append images of your products
              and/or display where possible so Activation Partners can ensure that the activation is
              the right fit for their business.
            </p>
            <p>
              BE TIMELY: Ensure you complete all relevant workflow items including product and
              assets deliveries by the required dates. If you are arranging your own freight of
              products for an activation run by our Activation Partners, ensure product is delivered
              by the required delivery date as Activation Partners have agreed to a set date for
              your activation. Respond to any messages from the Activation Partner to ensure a
              successful activation.
            </p>
            <p>
              BE CREATIVE: Every great activation starts with a creative idea and our spaces bring
              this idea to real life.
            </p>
            <p>
              BE COURTEOUS: If you are activating with your brand/agency team in a space, ensure you
              follow their ‘house’ rules.
            </p>
            <h3>HOUSE RULES | ACTIVATION PARTNERS ON THE MARKETPLACE</h3>
            <p>
              BE AUTHENTIC: Only collaborate with brands that are a good fit for your business. Your
              customers/ members will only appreciate relevant and meaningful activations that
              enhance their experience.
            </p>
            <p>
              BE TIMELY: Our brands are often activating these campaigns as part of a broader
              integrated campaign. Therefore it is important that, when you are activating for a
              brand, you activate on the scheduled dates and that you also complete the reports
              within 48 hours of the activation.
            </p>
            <p>
              BE A GREAT AMBASSADOR: For activations executed by our Activation Partners, ensure you
              take the time to read the activation description and any appended documents so that
              you are knowledgeable on the product/services you are promoting.
            </p>
            <p>
              BE GENUINE: We are a media activation and partnerships platform and our brands rely on
              the reach data that each Activation Partner provides. Please do not overrepresent your
              customer reach as it will create a negative experience for the brand.
            </p>
          </section>
        </div>
      </article>
    </Layout>
  );
};

export default TermsOfServicePage;
